import { firestore } from "./configure";
import { startOfDay } from "date-fns";
import * as firebase from "firebase";
import { BookingType } from "@anna/shared";

const COLLECTIONS = {
    RESTAURANTS: "restaurants",
    APPLICATIONS: "applications",
    NOTIFICATIONS: "notifications",
    FUNNELS: "funnels",
    ACTIVITY: "activity",
    USERS: "users",

    USER_INTERVIEW_PREFERENCES: "interviewPreferences",
    USER_INTERVIEW_TIME_SLOT_SUGGESTIONS: "interviewSuggestedTimeSlots",
    INTERVIEW_CUSTOM_TIMES: "interviewCustomTimes",

    RESTAURANT_TRIAL_PREFERENCES: "trialPreferences",
    RESTAURANT_TRIAL_TIME_SLOT_SUGGESTIONS: "trialSuggestedTimeSlots",

    BOOKINGS: "bookings",

    FUNNEL_TRIAL_PREFERENCES: "trialPreferences",
    TEST_REDIRECT_TIMESLOT_SUBMIT: "testTimesubmit",
    SHORTENED_URLS: "shortenedUrls",
    INVALIDATED_TOKENS: "invalidatedTokens",
};

export const getUserTimePreferences = (uid: string) =>
    firestore
        .collection(COLLECTIONS.USERS)
        .doc(uid)
        .collection(COLLECTIONS.USER_INTERVIEW_PREFERENCES)
        .orderBy("createTime", "desc")
        .limit(1);

export const getBookingById = (bookingId: string) =>
    firestore.collection(COLLECTIONS.BOOKINGS).doc(bookingId);

// TODO: should limit this by date just in case
export const getUserInterviewBookings = (uid: string, today = startOfDay(new Date())) =>
    firestore
        .collection(COLLECTIONS.BOOKINGS)
        .where("userId", "==", uid)
        .where("type", "==", BookingType.INTERVIEW)
        .where("endTime", ">", today)
        .orderBy("endTime", "desc");

export const submitSuggestedTimeSlots = async (
    uid: string,
    selectedTimeSlots: Contracts.TimeSlotBookingType[],
) => {
    try {
        await Promise.all(
            selectedTimeSlots.map(async (timeSlot: Contracts.TimeSlotBookingType) => {
                try {
                    await firestore
                        .collection(COLLECTIONS.USERS)
                        .doc(uid)
                        .collection(COLLECTIONS.USER_INTERVIEW_TIME_SLOT_SUGGESTIONS)
                        .add({
                            ...timeSlot,
                            createTime: firebase.firestore.FieldValue.serverTimestamp(),
                        });
                } catch (error) {
                    // @ts-ignore
                    throw new Error(error);
                }
            }),
        );
        return true;
    } catch (error) {
        // return error;
        // @ts-ignore
        throw new Error(error);
    }
};

export const getRestaurantTimePreferences = (
    restaurantId: string,
    type: Contracts.BookingType,
) =>
    firestore
        .collection(COLLECTIONS.RESTAURANTS)
        .doc(restaurantId)
        .collection(COLLECTIONS.RESTAURANT_TRIAL_PREFERENCES)
        .where("type", "==", type)
        .orderBy("createTime", "desc")
        .limit(1);

export const getRestaurantTrialBookings = ({
    restaurantId,
    type,
    today = startOfDay(new Date()),
}: {
    restaurantId: string;
    type: Contracts.BookingType;
    today?: Date;
}) =>
    firestore
        .collection(COLLECTIONS.BOOKINGS)
        .where("restaurantId", "==", restaurantId)
        .where("endTime", ">", today)
        .where("type", "==", type)
        .orderBy("endTime", "desc");

export const getFunnelTimePreferences = (funnelId: string, type: Contracts.BookingType) =>
    firestore
        .collection(COLLECTIONS.FUNNELS)
        .doc(funnelId)
        .collection(COLLECTIONS.FUNNEL_TRIAL_PREFERENCES)
        .where("type", "==", type)
        .orderBy("createTime", "desc")
        .limit(1);

export const getInterviewCustomTimes = (uid: string, funnelId: string) =>
    firestore
        .collection(COLLECTIONS.USERS)
        .doc(uid)
        .collection(COLLECTIONS.INTERVIEW_CUSTOM_TIMES)
        .where("funnelId", "==", funnelId)
        .orderBy("createTime", "desc")
        .limit(1);

export const addSubmitTestTimes = async (selectedTimeSlots: any) => {
    try {
        await firestore
            .collection(COLLECTIONS.TEST_REDIRECT_TIMESLOT_SUBMIT)

            .add({
                selectedTimeSlots,
                createTime: firebase.firestore.FieldValue.serverTimestamp(),
            });
    } catch (error) {
        console.error(error);

        return error;
    }
};

export const getShortenedUrlDetails = (documentId: string) => {
    return firestore.collection(COLLECTIONS.SHORTENED_URLS).doc(documentId);
};

export async function isTokenInvalidated(token: string) {
    try {
        const query = await firestore
            .collection(COLLECTIONS.INVALIDATED_TOKENS)
            .where("token", "==", token)
            .limit(1)
            .get();

        return !query.empty;
    } catch (error) {
        throw new Error(`Failed to check if token is invalidated ${token}. ${error}`);
    }
}
