import React from "react";
import { RouteComponentProps } from "react-router-dom";
import CustomTimes from "../../components/CustomTimes";
import { SchedulingType } from "@anna/shared";

interface MatchParams {
    readonly token: string;
}

export default function InterviewCustomTimes({
    match,
}: RouteComponentProps<MatchParams>) {
    return (
        <CustomTimes
            schedulingType={SchedulingType.Interview}
            token={match.params.token}
        />
    );
}
