import { StyleSheet } from "react-native";
import { StyleGuide } from "../../../constants/StyleGuide";

export const sharedStyles = StyleSheet.create({
    container: {
        flex: 1,
        height: "100%",
        paddingTop: StyleGuide.spacing.small,
        paddingBottom: StyleGuide.spacing.base,
    },

    content: {
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
    },

    headline: {
        ...StyleGuide.typography.h2,
        lineHeight: 40,
        color: StyleGuide.palette.basePepper,
    },

    subHeadline: {
        fontSize: 20,
        padding: 5,
        paddingLeft: 24,
        paddingRight: 24,
        textAlign: "center",
        lineHeight: 28,
        fontWeight: "400",
        color: StyleGuide.palette.basePepper,
    },
});
