import React from "react";
import { View } from "react-native";
import { useSharedPoolApplicantAccept } from "../../../../hooks/useSharedPoolApplicantActions";
import AppText from "../../../Text/AppText";
import useTranslation from "../../../../services/language";
import { SmallSpace } from "../../../Spacing";
import Footer from "../Footer";
import Header from "../Header";
import { sharedStyles } from "../styles";
import ScreenLoader from "../../../Loading/ScreenLoader";
import ExpiredLink from "../ExpiredLink";

interface SharedPoolProps {
    readonly token: string;
}

const AcceptContactRequest = (props: SharedPoolProps) => {
    const { token } = props;
    const l = useTranslation();
    const { isLoading, data } = useSharedPoolApplicantAccept(token);

    if (isLoading) return <ScreenLoader />;

    if (data?.hasPreviousResponse) {
        return <ExpiredLink token={token} />;
    }

    const headlineText = l("Great");
    const subHeadlineText = l("has been notified and will send you a message soon.");

    return (
        <>
            <View style={sharedStyles.container}>
                <Header logo={data?.restaurantLogo} />

                <View style={sharedStyles.content}>
                    <AppText style={sharedStyles.headline}>{headlineText}!</AppText>
                    <SmallSpace />
                    <AppText style={sharedStyles.subHeadline}>
                        {data?.userName} {subHeadlineText}
                    </AppText>
                </View>

                <Footer />
            </View>
        </>
    );
};

export default AcceptContactRequest;
