import React from "react";
import { View, StyleSheet } from "react-native";
import { Link } from "react-router-dom";
import useTranslation from "../../../../services/language";
import { StyleGuide } from "../../../../constants/StyleGuide";
import AppText from "../../../Text/AppText";
import { SmallSpace } from "../../../Spacing";
import Header from "../Header";
import Footer from "../Footer";
import { sharedStyles } from "../styles";

export interface Props {
    readonly token: string;
}

const ExpiredLink = (props: Props) => {
    const { token } = props;
    const l = useTranslation();

    const headlineText = l("This link has expired.");
    const subHeadlineText = l("I've changed my mind,");
    const subSubHeadlineText = l("I don’t want to be contacted");

    return (
        <>
            <View style={sharedStyles.container}>
                <Header />

                <View style={sharedStyles.content}>
                    <AppText style={sharedStyles.headline}>{headlineText}</AppText>
                    <SmallSpace />
                    <AppText style={sharedStyles.subHeadline}>
                        {subHeadlineText}
                        <View>
                            <Link to={`/sharedPoolContact/optout/${token}`}>
                                <AppText style={styles.linkText}>
                                    {subSubHeadlineText}
                                </AppText>
                            </Link>
                        </View>
                    </AppText>
                </View>

                <Footer />
            </View>
        </>
    );
};

export default ExpiredLink;

const styles = StyleSheet.create({
    linkText: {
        color: StyleGuide.palette.cucumber,
    },
});
