import React from "react";
import { RouteComponentProps } from "react-router-dom";
import SharedPoolApplicants from "../../components/SharedPool/index";

export interface MatchParams {
    readonly token: string;
}

export default function SharedTalentPool({ match }: RouteComponentProps<MatchParams>) {
    return <SharedPoolApplicants token={match.params.token} />;
}
