import { isBefore, addHours } from "date-fns";
import jwt_decode from "jwt-decode";

const aWeekInHours = 168; // 7 days

export function isTokenExpired(issuedAt: number, timeInHoursToExpiration: number) {
    return isBefore(addHours(issuedAt * 1000, timeInHoursToExpiration), new Date());
}

interface TokenDetails {
    token: string;
    timeInHoursToExpiration?: number;
}

export function getTokenDetails({
    token,
    timeInHoursToExpiration = aWeekInHours,
}: TokenDetails): { token: string; isExpired: boolean } {
    const { iat, ...rest } = jwt_decode<{ iat: number }>(token);

    const isExpired = isTokenExpired(iat, timeInHoursToExpiration);
    return {
        token,
        isExpired,
        ...rest,
    };
}
