import React from "react";
import styles from "./styles.module.scss";
import joinCls from "../../styles/joinClasses";
import Lottie from "react-lottie";

const loader = require("../../assets/lottie/loader.json");
const loaderOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
};

interface ButtonProps {
    readonly testId?: string;
    readonly title: string;
    readonly disabled?: boolean;
    readonly transparent?: boolean;
    readonly className?: string;
    readonly isLoading?: boolean;
    readonly onClick?: () => void;
}

export default function Button(props: ButtonProps) {
    return (
        <button
            className={joinCls(
                styles.button,
                props.transparent && styles.buttonTransparent,
                props.disabled && styles.buttonDisabled,
                props.disabled && !props.transparent && styles.buttonDisabledBackground,
                props.className,
            )}
            data-testid={props.testId}
            disabled={props.disabled}
            onClick={props.onClick}>
            {!props.isLoading ? props.title : <Lottie options={loaderOptions} />}
        </button>
    );
}
