import React, { useState } from "react";
import range from "lodash.range";
import TimeSlot from "../components/TimeSlot";
import { generateDays } from "../helpers/generateDays";
import { format, isSaturday, isSunday } from "date-fns";
import { addSubmitTestTimes } from "../firebase/firestore";
import HandClap from "../assets/HandClap";
import { SubHeadline, Headline, Divider } from "../components/SuccessMessage";
import styled from "styled-components";
import { ITheme } from "../constants/theme";
import { Dates, DateTimeConversion } from "@anna/shared";

const AppContainer = styled.div`
    padding-top: 24px;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
`;

const ButtonWrapper = styled.div`
    width: 100%;
    position: fixed;
    bottom: 0;
    box-shadow: 0px 10px 24px 48px #fff;
    background-color: #fff;
    padding: 0px 24px 20px;
`;

const SubmitButton = styled.button`
    background-color: ${({ disabled, theme }: { disabled: boolean; theme: ITheme }) =>
        disabled ? "grey" : theme.primaryColor};
    width: calc(100% - 48px);
    color: #fff;
    border: none;
    padding: 12px 16px;
    font-size: 20px;
    border-radius: 30px;
`;

const days = generateDays();
/**
 * TO BE REMOVED (WAS AN EXPERIMENT)
 */
export default function TypeformCompletion() {
    const [selection, setSelection] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    if (isSubmitted) {
        return (
            <>
                <div style={{ textAlign: "center" }}>
                    <HandClap />
                </div>
                <Headline>Exciting!</Headline>
                <SubHeadline>
                    We will doublecheck our calendar and we will get back to you! <br />
                    <br /> Thanks again!
                </SubHeadline>
                <Divider />
            </>
        );
    }
    return (
        <AppContainer>
            <h1>Hey, thanks for completing the application</h1>
            <div>
                To make things faster in case we proceed with an interview, please share
                your availability with us for the next week
            </div>
            {days
                .filter(day => !isSaturday(day) && !isSunday(day))
                .map((day: Date) => {
                    return (
                        <div>
                            <h1>{Dates.longLocalDateFormat(day)}</h1>
                            {range(0, 2).map(id => {
                                const times = id % 2 === 0 ? [420, 780] : [780, 1020];
                                const startTime = DateTimeConversion.minutesToHoursAndMinutes(
                                    times[0],
                                );
                                const endTime = DateTimeConversion.minutesToHoursAndMinutes(
                                    times[1],
                                );
                                const dayFormat = format(day, "yyyy-MM-dd");
                                const selected = `${dayFormat}-${times[0]}-${times[1]}`;

                                const isSelected = selection.find((time: string) => {
                                    return time === selected;
                                });
                                return (
                                    <TimeSlot
                                        key={id}
                                        startTime={startTime}
                                        endTime={endTime}
                                        isSelected={isSelected}
                                        isBooked={false}
                                        handleSelection={() =>
                                            isSelected
                                                ? setSelection(
                                                      selection.filter(
                                                          (time: string) =>
                                                              time !== selected,
                                                      ),
                                                  )
                                                : setSelection([...selection, selected])
                                        }
                                    />
                                );
                            })}
                        </div>
                    );
                })}
            <ButtonWrapper>
                <SubmitButton
                    disabled={isLoading}
                    onClick={async () => {
                        setIsLoading(true);
                        const error = await addSubmitTestTimes(selection);
                        if (!error) {
                            setIsSubmitted(true);
                        }
                        setIsLoading(false);
                    }}>
                    {isLoading ? "Loading.." : "Confirm"}
                </SubmitButton>
            </ButtonWrapper>
        </AppContainer>
    );
}
