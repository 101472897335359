import React from "react";
import { Divider } from "../../Divider";
import styles from "./styles.module.scss";
import useTranslation from "../../../services/language";
import joinCls from "../../../styles/joinClasses";

interface HeaderProps {
    readonly logo: string;
    readonly title: string;
    readonly subtitle?: string;
    readonly children: React.ReactNode;
    readonly withDivider?: boolean;
}

export default function Header(props: HeaderProps) {
    const l = useTranslation();
    const withDivider = props.withDivider ?? true;

    return (
        <>
            <div className={styles.container}>
                <img className={styles.logo} src={props.logo} />
                <h3
                    className={joinCls(
                        styles.title,
                        props.subtitle && styles.withSmallMargin,
                    )}>
                    {props.title}
                </h3>
                {props.subtitle && <p className={styles.subtitle}>{props.subtitle}</p>}
                {props.children}
            </div>

            {withDivider && <Divider className={styles.divider} />}
        </>
    );
}
