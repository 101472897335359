import { useEffect, useState } from "react";
import {
    getUserInterviewBookings,
    getRestaurantTrialBookings,
} from "../firebase/firestore";
import { Booking } from "../types/index";

export function useRestaurantTrialBookings(
    rid: string,
    type: Contracts.BookingType,
    callback?: () => void,
) {
    const [bookedDates, setBookedDates] = useState<Booking[]>([]);

    useEffect(() => {
        const unsubscribe = getRestaurantTrialBookings({
            restaurantId: rid,
            type,
        }).onSnapshot(
            query => {
                const bookings: Booking[] = query.docs.map(doc => {
                    const { startTime, endTime } = doc.data();
                    return {
                        id: doc.id,
                        startTime: startTime.toDate(),
                        endTime: endTime.toDate(),
                    };
                });
                setBookedDates([...bookedDates, ...bookings]);
                callback?.();
            },
            err => {
                console.log(err);
            },
        );
        return unsubscribe;
    }, [rid, type]);

    return bookedDates;
}
