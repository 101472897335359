import React from "react";
import { Transition } from "react-transition-group";

const defaultDuration = 300;

const defaultStyle = duration => ({
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
    width: "100%",
});

const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
};

interface FadeProps {
    readonly inProp: boolean;
    readonly duration?: number;
    readonly children?: React.ReactNode;
}

export default function Fade(props: FadeProps) {
    const duration = props.duration ?? defaultDuration;

    return (
        <Transition in={props.inProp} timeout={duration} unmountOnExit>
            {state => (
                <div
                    style={{
                        ...defaultStyle(duration),
                        ...transitionStyles[state],
                    }}>
                    {props.children}
                </div>
            )}
        </Transition>
    );
}
