import React from "react";
import ContentLoader from "react-content-loader";

const Loader = () => (
    <ContentLoader
        height={465}
        width={400}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#d5d1d9">
        {/* Only SVG shapes */}
        {/* ROW 1 */}
        <rect x="15" y="0" rx="5" ry="5" width="300" height="30" />
        <rect x="15" y="50" rx="20" ry="20" width="80" height="40" />
        <rect x="110" y="50" rx="20" ry="20" width="80" height="40" />
        <rect x="205" y="50" rx="20" ry="20" width="80" height="40" />
        <rect x="300" y="50" rx="20" ry="20" width="80" height="40" />
        {/* ROW 2 */}
        <rect x="15" y="105" rx="20" ry="20" width="80" height="40" />
        <rect x="110" y="105" rx="20" ry="20" width="80" height="40" />
        <rect x="205" y="105" rx="20" ry="20" width="80" height="40" />
        <rect x="300" y="105" rx="20" ry="20" width="80" height="40" />
        {/* ROW 3 */}
        <rect x="15" y="165" rx="20" ry="20" width="80" height="40" />
        <rect x="110" y="165" rx="20" ry="20" width="80" height="40" />
        <rect x="205" y="165" rx="20" ry="20" width="80" height="40" />
        <rect x="300" y="165" rx="20" ry="20" width="80" height="40" />
        {/* ROW 4 */}
        <rect x="15" y="250" rx="5" ry="5" width="300" height="30" />
        <rect x="15" y="300" rx="20" ry="20" width="80" height="40" />
        <rect x="110" y="300" rx="20" ry="20" width="80" height="40" />
        <rect x="205" y="300" rx="20" ry="20" width="80" height="40" />
        <rect x="300" y="300" rx="20" ry="20" width="80" height="40" />
        {/* ROW 5 */}
        <rect x="15" y="360" rx="20" ry="20" width="80" height="40" />
        <rect x="110" y="360" rx="20" ry="20" width="80" height="40" />
        <rect x="205" y="360" rx="20" ry="20" width="80" height="40" />
        <rect x="300" y="360" rx="20" ry="20" width="80" height="40" />
        {/* ROW 6 */}
        <rect x="15" y="420" rx="20" ry="20" width="80" height="40" />
        <rect x="110" y="420" rx="20" ry="20" width="80" height="40" />
        <rect x="205" y="420" rx="20" ry="20" width="80" height="40" />
        <rect x="300" y="420" rx="20" ry="20" width="80" height="40" />
    </ContentLoader>
);

export default Loader;
