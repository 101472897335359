import React from "react";
import { StyleSheet, View } from "react-native";
import { StyleGuide } from "../../constants/StyleGuide";
import AppText from "./AppText";

export default function LongText({ title, body }: { title?: string; body: string }) {
    return (
        <View>
            {title && (
                <View style={styles.titleWrapper}>
                    <AppText style={styles.title}>{title}</AppText>
                </View>
            )}
            <View style={styles.bodyWrapper}>
                <AppText style={styles.body}>{body}</AppText>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    titleWrapper: {
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
    },
    title: {
        ...StyleGuide.typography.body,
        color: StyleGuide.palette.basePepper,
    },
    bodyWrapper: {
        paddingTop: StyleGuide.spacing.mini,
        paddingBottom: StyleGuide.spacing.base,
    },
    body: {
        ...StyleGuide.typography.largeLight,
    },
});
