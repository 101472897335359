import React, { Fragment } from "react";
import { Colors } from "../../constants/colors";
import TimeSlot from "../TimeSlot";
import HandClap from "../../assets/HandClap";
import { TimeSlotType } from "../../types/index";
import styled from "styled-components";
import styles from "./styles.module.scss";
import { DateTimeConversion, Dates } from "@anna/shared";

export default function SuccessMessage({
    selectedTimeSlots,
}: {
    selectedTimeSlots: TimeSlotType[];
}) {
    return (
        <>
            <div style={{ textAlign: "center" }}>
                <HandClap />
            </div>
            <Headline>Exciting!</Headline>
            <SubHeadline>
                We will doublecheck our calendar and we will get back to you with a time!{" "}
                <br />
                <br /> Here are the timeslots you selected:
            </SubHeadline>
            <Divider />
            <div style={{}}>
                {selectedTimeSlots.map(timeSlot => (
                    <Fragment key={`${timeSlot.day}-${timeSlot.startTimeInMinutes}`}>
                        <div className={styles.title}>
                            {Dates.longLocalDateFormat(
                                DateTimeConversion.dayMinutesToDate(
                                    timeSlot.day,
                                    timeSlot.startTimeInMinutes,
                                ),
                            )}
                        </div>
                        <div style={{ paddingLeft: 24, paddingRight: 24 }}>
                            <TimeSlot
                                startTime={DateTimeConversion.minutesToHoursAndMinutes(
                                    timeSlot.startTimeInMinutes,
                                )}
                                isBooked={false}
                                isSelected
                                handleSelection={() => {}}
                            />
                        </div>
                    </Fragment>
                ))}
            </div>
            <SubHeadline>
                Do you have any questions? <br />
                Just reply to the SMS we sent you.
            </SubHeadline>
        </>
    );
}

export const Headline = styled.h2`
    padding: 20px;
    font-weight: 500;
    padding-bottom: 0;
    text-align: center;
`;

export const SubHeadline = styled.h4`
    font-size: 14px;
    padding: 5px;
    padding-left: 24px;
    padding-right: 24px;
    text-align: center;
    font-weight: lighter;
    color: ${Colors.darkestFont};
`;

export const Divider = styled.hr`
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 24px;
    border: none;
    border-bottom: 1px solid ${Colors.lightBorder};
`;
