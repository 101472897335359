import React, { useState, useEffect } from "react";
import "./App.css";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    RouteProps,
    matchPath,
    useLocation,
    Redirect,
} from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary";
import { __STAGING__ } from "./config/environment";
import { ThemeProvider } from "styled-components";
import theme from "./constants/theme";
import ShortenedLinkRedirect from "./routes/ShortenedLinkRedirect";
import SharedTalentPool from "./routes/SharedTalentPool";
import InterviewCustomTimes from "./routes/InterviewCustomTimes";
import TrialCustomTimes from "./routes/TrialCustomTimes/index";
import TypeformCompletion from "./routes/TypeformCompletion";
import Message from "./components/Message";
import InterviewReschedule from "./routes/InterviewReschedule";
import TrialReschedule from "./routes/TrialReschedule";
import InterviewCancel from "./routes/InterviewCancel";
import TrialCancel from "./routes/TrialCancel";
import SharedPoolContactAccept from "./routes/SharedTalentPool/acceptContactRequest";
import SharedPoolContactDecline from "./routes/SharedTalentPool/declineContactRequest";
import SharedPoolContactOptout from "./routes/SharedTalentPool/optoutContactRequest";
import Expired from "./routes/Expired";
import { AppRoute } from "./routes";
import ScreenLoader from "./components/Loading/ScreenLoader";
import { useTokenValidator, TokenResult } from "./hooks/useTokenValidator";

/**
 * * A route component which validates the token from the route params
 * A route is authenticated if the provided token is in a valid format, it is not expired nor invalidated.
 * Note: Route path must include "token" property, e.g. "/interview/schedule/:token".
 */
const RouteAuthenticated = (props: RouteProps) => {
    const location = useLocation();

    const match = matchPath<{ token: string }>(location.pathname, {
        path: props.path,
    });

    const tokenResult = useTokenValidator(match?.params.token);

    switch (tokenResult) {
        case TokenResult.Correct:
            return <Route {...props} />;
        case TokenResult.Expired:
        case TokenResult.Invalidated:
        case TokenResult.FailedToValidate:
            return <Redirect to={AppRoute.Expired} />;
        case TokenResult.Loading:
            return <ScreenLoader />;
    }
};

const Routes = () => (
    <ThemeProvider theme={theme}>
        <Router>
            <Switch>
                {/* SHARED TALENT POOL */}
                <RouteAuthenticated
                    exact
                    path={AppRoute.SharedTalentPool}
                    component={SharedTalentPool}
                />
                <RouteAuthenticated
                    exact
                    path={AppRoute.SharedTalentPoolAccept}
                    component={SharedPoolContactAccept}
                />
                <RouteAuthenticated
                    exact
                    path={AppRoute.SharedTalentPoolDecline}
                    component={SharedPoolContactDecline}
                />
                <RouteAuthenticated
                    exact
                    path={AppRoute.SharedTalentPoolOptOut}
                    component={SharedPoolContactOptout}
                />

                {/* INTERVIEWS */}
                <RouteAuthenticated
                    exact
                    path={AppRoute.InterviewSchedule}
                    component={InterviewCustomTimes}
                />
                <RouteAuthenticated
                    exact
                    path={AppRoute.InterviewReschedule}
                    component={InterviewReschedule}
                />
                <RouteAuthenticated
                    exact
                    path={AppRoute.InterviewCancel}
                    component={InterviewCancel}
                />

                {/* TRIALS */}
                <RouteAuthenticated
                    exact
                    path={AppRoute.TrialSchedule}
                    component={TrialCustomTimes}
                />
                <RouteAuthenticated
                    exact
                    path={AppRoute.TrialReschedule}
                    component={TrialReschedule}
                />
                <RouteAuthenticated
                    exact
                    path={AppRoute.TrialCancel}
                    component={TrialCancel}
                />

                <Route
                    exact
                    path={AppRoute.TypeformCompletion}
                    component={TypeformCompletion}
                />

                <Route exact path={AppRoute.Message} component={Message} />

                <Route exact path={AppRoute.Expired} component={Expired} />

                {/* ROOT REDIRECT FROM SHORT URL */}
                <Route exact path={AppRoute.Redirect} component={ShortenedLinkRedirect} />
            </Switch>
        </Router>
    </ThemeProvider>
);

const App: React.FC = () => {
    if (__STAGING__) {
        return <Routes />;
    }
    return (
        <ErrorBoundary>
            <Routes />
        </ErrorBoundary>
    );
};

export default App;
