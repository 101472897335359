import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Cancel from "../../components/Cancel";
import { SchedulingType } from "@anna/shared";

interface MatchParams {
    readonly token: string;
}

export default function InterviewCancel({ match }: RouteComponentProps<MatchParams>) {
    return (
        <Cancel schedulingType={SchedulingType.Interview} token={match.params.token} />
    );
}
