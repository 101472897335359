import { useEffect, useState } from "react";
import { getUserInterviewBookings } from "../firebase/firestore";
import { Booking } from "../types/index";

export function useUserInterviewBookings(uid: string, callback?: () => void) {
    const [bookedDates, setBookedDates] = useState<Booking[]>([]);

    useEffect(() => {
        const unsubscribe = getUserInterviewBookings(uid).onSnapshot(
            query => {
                const bookings: Booking[] = query.docs.map(doc => {
                    const { startTime, endTime } = doc.data();
                    return {
                        id: doc.id,
                        startTime: startTime.toDate(),
                        endTime: endTime.toDate(),
                    };
                });
                setBookedDates([...bookedDates, ...bookings]);
                callback?.();
            },
            err => {
                console.log(err);
            },
        );
        return unsubscribe;
    }, [uid]);

    return bookedDates;
}
