import { useEffect, useState } from "react";
import API from "../api";

export function useSharedPoolDetails(token: string, callback?: () => void) {
    const [poolDetails, setPoolDetails] = useState<Contracts.ApplicationDetailsDTO>();

    useEffect(() => {
        const fetchSessionDetails = async () => {
            const dto = { token };
            try {
                const response = await API.sharedPoolDetails(dto);
                if (response.isSuccess) {
                    setPoolDetails(response.result);
                    callback?.();
                }
            } catch (err) {
                console.error(err);
                setPoolDetails({});
            }
        };
        fetchSessionDetails();
    }, [token]);

    return poolDetails;
}
