import React from "react";
import useTranslation from "../../../services/language";
import { StyleGuide } from "../../../constants/StyleGuide";
import { View, Dimensions, StyleSheet, TouchableOpacity } from "react-native";
import AppText from "../../Text/AppText";
import LongText from "../../Text/LongText";
import { AppAvatar } from "../../AppAvatar";
import Tags from "../../Tags";
import { shortLocalDateFormat } from "../../../helpers/dates";
import { mapWorkHours, mapVisaType } from "@anna/shared";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "../Modals/customModal.css";

const screenWidth = Dimensions.get("window").width;
const iframeId = "iframeContainer";

export interface ApplicationProps {
    readonly application: Contracts.DBApplicationShape | null;
}

const ApplicantProfile = (props: ApplicationProps) => {
    const { application } = props;
    const l = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const avatarUri = {
        uri: application?.answers?.application_profile_picture?.content || "",
    };

    // TODO Hack to retry on 204 errors from google docs viewer
    const reloadIFrameIfRequired = () => {
        if (open && !loaded) {
            // Trigger reload
            if (document.getElementById(iframeId)?.getAttribute("src")) {
                // Trigger reload by setting src attribute again
                window.document
                    .getElementById(iframeId)
                    ?.setAttribute(
                        "src",
                        document.getElementById(iframeId)?.getAttribute("src") || "",
                    );
            }
            // Check after 2 seconds to see if content is loaded
            setTimeout(() => {
                const contentLoaded =
                    //@ts-ignore contentDocument inside the iframe
                    window.document.getElementById(iframeId)?.contentDocument === null;
                // Set only when loaded
                return contentLoaded ? setLoaded(true) : reloadIFrameIfRequired();
            }, 2000);
        }
    };

    if (!application) {
        return null;
    }

    const firstName = application?.answers?.application_firstname?.content;
    const lastName = application?.answers?.application_lastname?.content;
    const position = application?.answers?.application_position?.content?.label;
    const selfIntro = application?.answers?.application_self_intro?.content;
    const workExperience = application?.answers?.application_work_experience?.content;
    const spokenLanguages =
        application?.answers?.application_spoken_languages?.content?.labels;
    const cv = application?.answers?.application_cv?.content;
    const encodedUrl =
        cv && cv.replace("ø", "%C3%B8").replace("æ", "%C3%A6").replace("å", "%C3%A5");
    const email = application?.answers?.application_email?.content;
    const visaType = mapVisaType(
        application?.answers?.application_visa_type?.content
            ?.label as Contracts.TypeformVisaType,
    );
    const workHours = mapWorkHours(
        application?.answers?.application_workhours?.content
            ?.label as Contracts.TypeformWorkHours,
    );
    const createDate = application?.createTime?._seconds;

    return (
        <>
            <View style={styles.container}>
                <View style={styles.headerWrapper}>
                    <AppAvatar size={64} source={avatarUri} />
                    <View style={styles.namePositionWrapper}>
                        <AppText
                            numberOfLines={1}
                            style={{
                                ...StyleGuide.typography.subheadLight,
                                color: StyleGuide.palette.basePepper,
                            }}>
                            {firstName} {lastName}
                        </AppText>
                        <AppText style={styles.positionStyle}>{position}</AppText>
                    </View>
                    <View style={{ alignSelf: "flex-start", paddingTop: 10 }}>
                        <TouchableOpacity
                            style={styles.cvBtn}
                            onPress={() => setOpen(true)}>
                            <AppText style={styles.link}>See CV</AppText>
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={styles.detailsRow}>
                    <View style={styles.blockStyle}>
                        <AppText style={styles.positionLabel}>Applied</AppText>
                        <AppText style={styles.positionValue}>
                            {shortLocalDateFormat(new Date(createDate))}
                        </AppText>
                    </View>
                    <View style={styles.blockStyle}>
                        <AppText style={styles.positionLabel}>Hours</AppText>
                        <AppText style={styles.positionValue}>{workHours}</AppText>
                    </View>
                    <View style={styles.blockStyle}>
                        <AppText style={styles.positionLabel}>Visa</AppText>
                        <View style={styles.visaType}>
                            <AppText style={styles.positionValue}>{visaType}</AppText>
                        </View>
                    </View>
                </View>
                <View style={styles.divider} />
                <View style={styles.contentSpacing}>
                    <View>
                        <LongText title="About" body={selfIntro || ""} />
                        <LongText title="Work experience" body={workExperience || ""} />
                        {spokenLanguages && (
                            <Tags title="Languages" tags={spokenLanguages || []} />
                        )}
                        {/* <LongText
                            title="Applied on"
                            body={createTime && localDateFormat(createTime)}
                        /> */}
                    </View>
                </View>
                <Modal
                    open={open}
                    onClose={() => {
                        setOpen(false);
                        setLoaded(false);
                    }}
                    onAnimationEnd={reloadIFrameIfRequired}
                    center
                    classNames={{
                        animationIn: "customEnterAnimation",
                        animationOut: "customLeaveAnimation",
                        modal: "customModal",
                        overlay: "customOverlay",
                    }}
                    animationDuration={375}>
                    {!loaded ? <>Loading...</> : null}
                    <iframe
                        id={iframeId}
                        key={encodedUrl}
                        onLoad={() => setLoaded(true)}
                        src={`https://docs.google.com/viewer?url=${encodedUrl}&embedded=true`}
                        scrolling="auto"
                        style={{
                            position: "absolute",
                            left: 0,
                            top: 50,
                            width: "100%",
                            height: "100%",
                            border: "none",
                        }}></iframe>
                </Modal>
                <div className={`fade ${open ? "fadeIn" : "fadeOut"}`} />
            </View>
        </>
    );
};

export default ApplicantProfile;

const styles = StyleSheet.create({
    container: {
        width: screenWidth,
        paddingTop: StyleGuide.spacing.small,
        paddingBottom: StyleGuide.spacing.mini,
    },
    headerWrapper: {
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-end",
        paddingHorizontal: StyleGuide.spacing.base,
        paddingBottom: StyleGuide.spacing.medium,
        paddingTop: StyleGuide.spacing.small,
    },
    positionLabel: {
        textAlign: "center",
        ...StyleGuide.typography.extraSmall,
        color: StyleGuide.palette.baseVanilla,
        marginBottom: 5,
    },
    positionValue: {
        textAlign: "center",
        ...StyleGuide.typography.body,
        color: StyleGuide.palette.basePepper,
    },
    divider: {
        width: screenWidth,
        borderBottomWidth: 8.0,
        borderColor: StyleGuide.palette.baseSugar,
    },
    contentSpacing: {
        paddingTop: StyleGuide.spacing.mini,
        paddingHorizontal: StyleGuide.spacing.base,
    },
    visaType: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    blockStyle: {
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    detailsRow: {
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        paddingTop: StyleGuide.spacing.mini,
        paddingHorizontal: StyleGuide.spacing.base,
        paddingBottom: StyleGuide.spacing.base,
    },
    namePositionWrapper: {
        marginLeft: StyleGuide.spacing.small,
        flexDirection: "column",
        justifyContent: "center",
        alignSelf: "center",
        flexGrow: 1,
    },
    positionStyle: {
        marginTop: 4,
        marginBottom: 4,
        ...StyleGuide.typography.h3,
    },
    borderRadius20: { borderRadius: 20 },
    cvBtn: {
        paddingHorizontal: 12,
        paddingVertical: 6,
        backgroundColor: StyleGuide.palette.primary,
        borderRadius: 8,
    },
    link: {
        ...StyleGuide.typography.headline,
        color: StyleGuide.palette.baseSalt,
    },
    favoriteText: {
        ...StyleGuide.typography.headline,
        color: StyleGuide.palette.secondaryCucumber,
    },
});
