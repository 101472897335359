import React from "react";
import { TouchableOpacity, View, StyleSheet } from "react-native";
import ArrowLeft from "../../assets/svg/ArrowLeft";

interface HeaderIconButton {
    readonly icon?: JSX.Element;
    onPress: () => void;
    isTransparent?: boolean;
}
/**
 *
 * Default taking looks like a back button
 */
export default function HeaderIconButton({
    icon = <ArrowLeft />,
    onPress,
    isTransparent = false,
}) {
    return (
        <TouchableOpacity onPress={onPress}>
            <View
                style={[
                    styles.backButton,
                    !isTransparent && { backgroundColor: "#F8FBFB" },
                ]}>
                {icon}
            </View>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    backButton: {
        width: 40,
        height: 40,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8,
        zIndex: 3,
    },
});
