import React, { useState } from "react";
import styles from "./styles.module.scss";
import Header from "../CustomTimes/Header";
import useTranslation from "../../services/language";
import { useLocation } from "react-router-dom";
import Button from "../Button";
import API from "../../api";
import { addLineBreaks } from "../../helpers/text";
import joinCls from "../../styles/joinClasses";
import { ApiResponse } from "@anna/shared";

export interface MessageProps {
    readonly header: { title: string; subtitle?: string };
    readonly messageTitle: string;
    readonly restaurantLogo: string;
    readonly token: string;
    readonly additionalInformation?: React.ReactNode;
    readonly messageSent?: { title: string; subtitle: string };
    readonly buttonTitle?: string;
    readonly customSendRequest?: (message: string) => Promise<ApiResponse>;
}

export default function Message(props: MessageProps) {
    const l = useTranslation();
    const location = useLocation<MessageProps>();

    const {
        header,
        messageTitle,
        restaurantLogo,
        token,
        additionalInformation,
        messageSent,
        buttonTitle,
    } = location.state ?? props;

    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const messageSentText = {
        title: messageSent?.title ?? l("Message received!"),
        subtitle: addLineBreaks(
            messageSent?.subtitle ??
                l("We will get back to you as soon as possible,\nthanks!"),
        ),
    };

    return (
        <div className={styles.container}>
            <Header
                logo={restaurantLogo}
                title={isSent ? messageSentText.title : header.title}
                subtitle={isSent ? "" : header.subtitle}
                withDivider={false}>
                <p className={styles.messageTitle}>
                    {isSent ? messageSentText.subtitle : addLineBreaks(messageTitle)}
                </p>
            </Header>

            {!isSent && (
                <>
                    <textarea
                        data-testId="cant-make-time-textarea"
                        disabled={isLoading || isSent}
                        className={styles.message}
                        onChange={e => setMessage(e.target.value)}
                        placeholder={l("Your message")}
                    />

                    {additionalInformation}

                    <div
                        className={joinCls(
                            styles.buttonWrapper,
                            additionalInformation !== undefined && styles.noMargin,
                        )}>
                        <Button
                            testId="cant-make-time-send-button"
                            title={l(buttonTitle ?? "Send message")}
                            disabled={isLoading || isSent || message.length === 0}
                            isLoading={isLoading}
                            onClick={async () => {
                                setIsLoading(true);
                                const result = await (props.customSendRequest?.(
                                    message,
                                ) ??
                                    API.sendMessage({
                                        token,
                                        message,
                                    }));
                                if (result.isSuccess) {
                                    setIsSent(true);
                                }
                                setIsLoading(false);
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    );
}
