import { addDays } from "date-fns";
import { startOfDay } from "date-fns";
export function generateDays() {
    const today = startOfDay(new Date());
    return [
        today,
        addDays(today, 1),
        addDays(today, 2),
        addDays(today, 3),
        addDays(today, 4),
        addDays(today, 5),
        addDays(today, 6),
        addDays(today, 7),
    ];
}
