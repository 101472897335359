export enum FontName {
    Thin,
    Light,
    LightItalic,
    Regular,
    Medium,
    Bold,
}

type FontWeight =
    | "normal"
    | "bold"
    | "100"
    | "200"
    | "300"
    | "400"
    | "500"
    | "600"
    | "700"
    | "800"
    | "900";

type FontStyle = "normal" | "italic";

type Fonts = {
    readonly [key in FontName]: {
        weight: FontWeight;
        style?: FontStyle;
        web: string;
    };
};

const fonts: Fonts = {
    [FontName.Thin]: {
        weight: "100",
        web: "Roboto",
    },
    [FontName.Light]: {
        weight: "300",
        web: "Roboto",
    },
    [FontName.LightItalic]: {
        weight: "300",
        style: "italic",
        web: "Roboto",
    },
    [FontName.Regular]: {
        weight: "400",
        web: "Roboto",
    },
    [FontName.Medium]: {
        weight: "500",
        web: "Roboto",
    },
    [FontName.Bold]: {
        weight: "700",
        web: "Roboto",
    },
};

interface FontFamily {
    readonly fontFamily?: string;
    readonly fontWeight?: FontWeight;
    readonly fontStyle?: FontStyle;
}

export function withFont(name: FontName): FontFamily {
    const font = fonts[name];

    return {
        fontFamily: font.web,
        fontWeight: font.weight,
        fontStyle: font.style ?? "normal",
    };
}

export enum FontScaling {
    maxFontSizeMultiplier = 1.2,
}
