import { useEffect, useState } from "react";
import { getTokenDetails } from "../helpers/token";
import { isTokenInvalidated } from "../firebase/firestore";

export enum TokenResult {
    Correct,
    Invalidated,
    Expired,
    Loading,
    FailedToValidate,
}

export function useTokenValidator(token?: string) {
    const [result, setResult] = useState<TokenResult>(TokenResult.Loading);

    useEffect(() => {
        (async () => {
            if (token) {
                try {
                    // Check if token is expired or invalidated
                    const { isExpired } = getTokenDetails({ token });
                    if (isExpired) {
                        setResult(TokenResult.Expired);
                    } else if (await isTokenInvalidated(token)) {
                        setResult(TokenResult.Invalidated);
                    } else {
                        setResult(TokenResult.Correct);
                    }
                } catch (error) {
                    console.warn(`Failed to validate token ${token}. ${error}.`);
                    setResult(TokenResult.FailedToValidate);
                }
            }
        })();
    }, [token]);

    return result;
}
