import { TimeSlotType } from "../types/index";
export function validateSubmission({
    selectedTimeSlots,
    userId,
    funnelId,
}: {
    selectedTimeSlots: TimeSlotType[];
    userId: string;
    funnelId: string;
}) {
    if (selectedTimeSlots.length === 0) {
        alert("Please select at least one slot");
        return false;
    }
    if (!userId || !funnelId) {
        alert("Something went wrong, please try again");
        return false;
    }
    return true;
}
