import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { MatchParams } from "./index";
import OptoutContactRequest from "../../components/SharedPool/Applicant/OptoutContactRequest";
import { getTokenDetails } from "../../helpers/token";
import ExpiredLink from "../../components/SharedPool/Applicant/ExpiredLink/index";

export default function SharedPoolContactOptout({
    match,
}: RouteComponentProps<MatchParams>) {
    const token = match.params.token;
    const { isExpired } = getTokenDetails({ token });

    if (isExpired) return <ExpiredLink token={token} />;
    return <OptoutContactRequest token={token} />;
}
