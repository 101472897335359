import * as React from "react";
import Svg, { Path } from "react-native-svg";

interface ArrowRightProps {
    readonly fillColor?: string;
}

export default (props: ArrowRightProps) => (
    <Svg width={8} height={13} viewBox="0 0 8 13" fill="none">
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M.276 12.746a.82.82 0 010-1.226l4.653-4.284a1 1 0 000-1.472L.276 1.48a.819.819 0 010-1.225 1 1 0 011.33 0l6.118 5.633a.819.819 0 010 1.226l-6.117 5.633a1 1 0 01-1.331 0z"
            fill={props.fillColor ?? "#4C9999"}
        />
    </Svg>
);
