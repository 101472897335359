import React from "react";
import { StyleSheet, View, Image } from "react-native";

interface Props {
    logo?: string;
}

export default function ({ logo }: Props) {
    return logo ? (
        <Image style={styles.logo} source={{ uri: logo }} />
    ) : (
        // take up the space of the logo to avoid content jumping around
        <View style={styles.logo} />
    );
}

const styles = StyleSheet.create({
    logo: { width: 150, height: 150, margin: "auto" },
});
