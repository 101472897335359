import { Colors } from "./colors";
import { withFont, FontName } from "./Fonts";

export enum Spacing {
    mini = 8,
    small = 16,
    base = 24,
    medium = 32,
    large = 40,
    xlarge = 48,
}

const spacing = {
    mini: Spacing.mini,
    small: Spacing.small,
    base: Spacing.base,
    medium: Spacing.medium,
    large: Spacing.large,
    xlarge: Spacing.xlarge,
};

const palette = {
    white: "#fff",
    primary: "#4c9999",
    cucumber: "#0b7375",
    secondary: "rgb(76,153,153)",
    backgroundPrimary: "#d5e5ff",
    background: "#f2f2f2",
    border: "#f2f2f2",
    body: "#4f4f4f",
    muted: "#c1c1c1",
    light: "#ffffff",
    tabIconDefault: "#ccc",
    danger: "#ee6464",
    // OldSchool
    veryLightBorder: "rgba(102, 102, 102, 0.05)",
    systemMessageBg: "rgba(237, 237, 237, 0.2)",

    vanilla: "#839090",
    baseSalt: "#FFFFFF",
    baseSeaSalt: "#EDEDED",
    baseSugar: "#FAFAFA",
    basePepper: "#242828",
    basePepperAlpha: (alpha: number) => `rgba(36,40,40,${alpha})`,
    baseVanilla: "#839090",
    primaryAnanas: "#FFB432",
    secondaryCucumber: "#0B7375",
    secondaryMint: "#deecec",
    secondaryMintAlpha: "rgba(222,236,236, 0.2)",
    secondaryBasil: "#4c9999",
    secondaryCream: "#F8FBFB",
    secondaryMilk: "#EEF5F5",
    sugar: "#FAFAFA",
    coal: "#535C5C",
};

const typography = {
    body: {
        ...withFont(FontName.Regular),
        fontSize: 14,
        color: "#4f4f4f",
    },
    bodyMedium: {
        ...withFont(FontName.Medium),
        fontSize: 14,
        color: "#4f4f4f",
    },
    bodyLight: {
        ...withFont(FontName.Light),
        fontSize: 14,
        color: Colors.black,
    },
    bodyBold: {
        ...withFont(FontName.Bold),
        fontSize: 14,
        color: Colors.black,
    },
    modalHeadline: {
        ...withFont(FontName.Bold),
        fontSize: 20,
        color: "#030303",
    },
    modalHeadlineLight: {
        ...withFont(FontName.Light),
        fontSize: 22,
    },
    modalButton: {
        ...withFont(FontName.Regular),
        color: Colors.white,
        fontSize: 18,
    },
    listHeadline: {
        ...withFont(FontName.Bold),
        fontSize: 17,
    },
    headline: {
        ...withFont(FontName.Regular),
        fontSize: 14,
        color: "#030303",
    },
    headlineBold: {
        ...withFont(FontName.Bold),
        fontSize: 14,
        color: "#030303",
    },
    medium: {
        ...withFont(FontName.Regular),
        fontSize: 12,
        color: "#030303",
    },
    mediumLight: {
        ...withFont(FontName.Light),
        fontSize: 12,
    },
    mediumLightItalic: {
        ...withFont(FontName.LightItalic),
        fontSize: 12,
    },
    extraSmall: {
        ...withFont(FontName.Regular),
        fontSize: 10,
        color: "#c1c1c1",
    },
    small: {
        ...withFont(FontName.Regular),
        fontSize: 11,
        color: "#c1c1c1",
    },
    smallBold: {
        ...withFont(FontName.Bold),
        fontSize: 11,
        color: "#c1c1c1",
    },
    h1: {
        ...withFont(FontName.Bold),
        fontSize: 50,
        color: Colors.black,
    },
    h2: {
        ...withFont(FontName.Regular),
        fontSize: 28,
    },
    h2Light: {
        ...withFont(FontName.Light),
        fontSize: 28,
    },
    h2Thin: {
        ...withFont(FontName.Thin),
        fontSize: 28,
    },
    h3: {
        ...withFont(FontName.Regular),
        fontSize: 16,
        color: "#030303",
    },
    h4: {
        ...withFont(FontName.Regular),
        fontSize: 13,
        color: "#171717",
    },
    // New UI style
    headlineLight: {
        ...withFont(FontName.Light),
        fontSize: 32,
        color: palette.basePepper,
    },
    subheadLight: {
        ...withFont(FontName.Light),
        fontSize: 20,
        color: palette.basePepper,
    },
    subhead: {
        ...withFont(FontName.Regular),
        fontSize: 20,
        color: palette.basePepper,
    },
    smallLight: {
        ...withFont(FontName.Light),
        fontSize: 12,
        color: palette.basePepper,
    },
    largeLight: {
        ...withFont(FontName.Light),
        fontSize: 16,
        color: palette.basePepper,
    },
    large: {
        ...withFont(FontName.Regular),
        fontSize: 16,
        color: palette.basePepper,
    },
    largeBold: {
        ...withFont(FontName.Bold),
        fontSize: 16,
        color: palette.basePepper,
    },
    chat: {
        ...withFont(FontName.Light),
        fontSize: 14,
        color: Colors.black,
    },
    chatSystem: {
        ...withFont(FontName.Light),
        fontSize: 12,
        color: Colors.black,
    },
    chatSystemRegular: {
        ...withFont(FontName.Regular),
        fontSize: 12,
        color: Colors.black,
    },
    regular: {
        // Idea so to access fontFamily and overwrite the rest
        ...withFont(FontName.Regular),
        fontSize: 12,
        color: palette.basePepper,
    },
    avatarTitle: {
        ...withFont(FontName.Medium),
        fontSize: 20,
        color: "#fff",
    },
};

export const StyleGuide = {
    spacing,
    palette,
    typography,
};

type PaletteColorNames = keyof typeof StyleGuide.palette;
export type PaletteColors = typeof StyleGuide.palette[PaletteColorNames];
