export enum Colors {
    black = "#171717",
    tintColor = "#2f95dc",
    tabIconDefault = "#ccc",
    tabIconSelected = "#2f95dc",
    tabBar = "#fefefe",
    errorBackground = "red",
    errorText = "#fff",
    warningBackground = "#EAEB5E",
    warningText = "#666804",
    noticeBackground = "#2f95dc",
    noticeText = "#fff",
    primaryColor = "#0079FF",
    lightBorder = "#d8d8d8",
    labelColorApplied = "#fab801", //'#fab801',
    labelColorInterview = "#007c8a", //'#512da8',
    labelColorTrial = "#02d1c2", //'#546e7a',
    labelColorHired = "#51e164", //'#2e7d32',
    labelColorRejected = "#9da49e", //'#b71c1c',
    chatBubbleExternalColor = "#f4f4f5",
    darkestFont = "#333",
    darkFont = "#5c5c5c",
    lightFont = "#fff",
    white = "#fff",
}
