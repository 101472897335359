import React from "react";
import { Text, TextProps } from "react-native";
import { FontScaling } from "../../constants/Fonts";

interface Props extends TextProps {
    children: any;
}

export default function AppText({ children, ...otherProps }: Props) {
    return (
        <Text {...otherProps} maxFontSizeMultiplier={FontScaling.maxFontSizeMultiplier}>
            {children}
        </Text>
    );
}
