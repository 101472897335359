import React from "react";
import { StyleSheet, View, Image } from "react-native";
import { StyleGuide } from "../../../constants/StyleGuide";
import AppText from "../../Text/AppText";
import useTranslation from "../../../services/language";

interface Props {}

export default function (_: Props) {
    const l = useTranslation();

    const footerText = l("Powered by anna");

    return (
        <View style={styles.footer}>
            <Image
                source={require("../../../assets/anna-logo.png")}
                style={styles.annaLogo}
            />
            <AppText style={styles.footerText}>{footerText}</AppText>
        </View>
    );
}

const styles = StyleSheet.create({
    footer: { justifyContent: "center" },

    footerText: { ...StyleGuide.typography.h3, textAlign: "center" },

    annaLogo: { width: 75, height: 75, margin: "auto" },
});
