import React, { useState } from "react";
import jwt_decode from "jwt-decode";
import useTranslation from "../../services/language";
import { StyleGuide } from "../../constants/StyleGuide";
import { View, Dimensions, StyleSheet, TouchableOpacity } from "react-native";
import AppText from "../Text/AppText";
import { Header } from "../../screens/Layout/Header";
import { useSharedPoolDetails } from "../../hooks/useSharedPoolDetails";
import "react-responsive-modal/styles.css";
import ApplicantProfile from "./Profile";
import "./Modals/customModal.css";
import HeaderIconButton from "../../screens/Layout/HeaderIconButton";
import ArrowRight from "../../assets/svg/ArrowRight";
import API from "../../api";
import { ApplicationState } from "@anna/shared";
import { getTokenDetails } from "../../helpers/token";

const screenWidth = Dimensions.get("window").width;

interface SharedPoolProps {
    readonly token: string;
}

export interface SharedPoolContactToken {
    readonly userId: string;
    readonly funnelId: string;
    readonly restaurantId: string;
    readonly sharedPoolApplicationId: string;
    readonly iat: number;
}

export interface SharedPoolToken {
    readonly userId: string;
    readonly restaurantId: string;
    readonly applicantIds: string[];
    readonly iat: number;
}

const timeInHoursToExpiration = 2;

const SharedPoolApplicants = (props: SharedPoolProps) => {
    const { token } = props;
    const l = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [isInitialized, setIsInitialized] = useState(false);
    const { userId, restaurantId, applicantIds, iat } = jwt_decode<SharedPoolToken>(
        token,
    );

    const [swipeIndex, setSwipeIndex] = useState(0);

    const { isExpired } = getTokenDetails({
        token,
        timeInHoursToExpiration,
    });

    const details = useSharedPoolDetails(token, () => {
        setIsLoading(false);
        setIsInitialized(true);
    });

    const goForward = () => {
        if (swipeIndex < applicantIds.length - 1) setSwipeIndex(swipeIndex + 1);
    };
    const goBack = () => {
        if (swipeIndex > 0) setSwipeIndex(swipeIndex - 1);
    };

    const saveUserAction = async (action: ApplicationState) => {
        const userActionDto = {
            userId,
            applicantId: applicantIds[swipeIndex],
            restaurantId,
            action,
            token,
        } as Contracts.ApplicantActionByUser;
        const result = await API.sharedPoolUserAction(userActionDto);
        if (result.isSuccess) {
            goForward();
        }
    };

    if (isExpired) {
        // TODO Update with appropriate screen
        return <div>Get a new token please...</div>;
    }
    if (isLoading || !isInitialized) {
        // TODO Update with appropriate screen
        return <div>Wait for it...</div>;
    }

    if (!applicantIds || applicantIds.length === 0) {
        return <View />;
    }

    const application = details?.applications
        ? (details.applications[swipeIndex] as With.Id<Contracts.DBApplicationShape>)
        : null;
    return (
        <>
            <Header>
                <View style={styles.headerLeft}>
                    {swipeIndex > 0 ? (
                        <HeaderIconButton onPress={goBack} />
                    ) : (
                        <View style={styles.emptyButton} />
                    )}
                </View>
                <View style={styles.paginationWrapper}>
                    <AppText style={styles.paginationText}>
                        {swipeIndex + 1} / {applicantIds.length}
                    </AppText>
                </View>
                <View style={styles.headerRight}>
                    {swipeIndex + 1 < applicantIds.length ? (
                        <HeaderIconButton icon={<ArrowRight />} onPress={goForward} />
                    ) : (
                        <View style={styles.emptyButton} />
                    )}
                </View>
            </Header>
            <View style={styles.container}>
                <ApplicantProfile application={application} />
            </View>
            <View style={styles.actions}>
                <TouchableOpacity
                    style={styles.favoriteBtn}
                    onPress={async () => await saveUserAction(ApplicationState.REJECTED)}>
                    <AppText style={styles.favoriteText}>Reject</AppText>
                </TouchableOpacity>

                <TouchableOpacity
                    style={styles.inviteBtn}
                    onPress={async () =>
                        await saveUserAction(ApplicationState.INTERESTED)
                    }>
                    <AppText style={styles.link}>Shortlist</AppText>
                </TouchableOpacity>
            </View>
        </>
    );
};

export default SharedPoolApplicants;

const styles = StyleSheet.create({
    container: {
        width: screenWidth,
        paddingTop: StyleGuide.spacing.small,
        paddingBottom: StyleGuide.spacing.mini,
    },
    emptyButton: {
        width: 40,
        height: 40,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8,
        zIndex: 3,
    },
    inviteBtn: {
        paddingTop: 14,
        paddingBottom: 12,
        paddingHorizontal: StyleGuide.spacing.base,
        alignContent: "center",
        backgroundColor: StyleGuide.palette.primary,
        borderRadius: 12,
        width: "45%",
        // @ts-ignore
        minWidth: "150px",
    },
    favoriteBtn: {
        paddingTop: 14,
        paddingBottom: 12,
        paddingHorizontal: StyleGuide.spacing.base,
        alignContent: "center",
        borderWidth: 1,
        borderColor: StyleGuide.palette.secondaryBasil,
        backgroundColor: StyleGuide.palette.baseSalt,
        borderRadius: 12,
        width: "45%",
        // @ts-ignore
        minWidth: "150px",
    },
    actions: {
        flex: 1,
        flexDirection: "row",
        paddingHorizontal: StyleGuide.spacing.base,
        justifyContent: "space-between",
        paddingBottom: StyleGuide.spacing.base,
        paddingTop: StyleGuide.spacing.small,
    },
    link: {
        ...StyleGuide.typography.headline,
        color: StyleGuide.palette.baseSalt,
        textAlign: "center",
    },
    favoriteText: {
        ...StyleGuide.typography.headline,
        color: StyleGuide.palette.secondaryCucumber,
        textAlign: "center",
    },
    headerLeft: {
        flexDirection: "row",
    },
    headerRight: { flexDirection: "row" },
    paginationWrapper: {
        backgroundColor: StyleGuide.palette.secondaryCream,
        padding: StyleGuide.spacing.small,
        paddingTop: StyleGuide.spacing.mini,
        paddingBottom: StyleGuide.spacing.mini,
        borderRadius: StyleGuide.spacing.base,
    },
    paginationText: {
        ...StyleGuide.typography.medium,
        color: StyleGuide.palette.primary,
    },
});
