import { Client } from "@anna/shared";
import config from "../config";

// prettier-ignore
export function Api(client: Client) {
    return {        
        interviewDetails: (dto: Contracts.InterviewDetailsByApplicant) => client.execute<Contracts.InterviewTrialDetailsDTO>("/interview/details", dto, true),
        confirmInterview: (dto: Contracts.ConfirmInterviewByApplicant) => client.execute("/interview/confirm", dto),
        rescheduleInterview: (dto: Contracts.RescheduleInterviewByApplicant) => client.execute("/interview/reschedule", dto),
        cancelInterview: (dto: Contracts.CancelInterviewByApplicant)  => client.execute("/interview/cancel", dto),
        trialDetails: (dto: Contracts.TrialDetailsByApplicant) => client.execute<Contracts.InterviewTrialDetailsDTO>("/trial/details", dto, true),
        confirmTrial: (dto: Contracts.ConfirmTrialByApplicant) => client.execute("/trial/confirm", dto),
        rescheduleTrial: (dto: Contracts.RescheduleTrialByApplicant) => client.execute("/trial/reschedule", dto),
        cancelTrial: (dto: Contracts.CancelTrialByApplicant) => client.execute("/trial/cancel", dto),
        sendMessage: (dto: Contracts.SendMessageByApplicant) => client.execute("/message", dto),
        sharedPoolDetails: (dto: Contracts.ApplicationDetailsByUser) => client.execute<Contracts.ApplicationDetailsDTO>("/sharedPool/details", dto, true),
        sharedPoolUserAction: (dto: Contracts.ApplicantActionByUser) => client.execute("/sharedPool/userAction", dto),
        sharedPoolContactAccept: (dto: Contracts.SharedPoolActionByApplicant) => client.execute<Contracts.SharedPoolActionDetailsDTO>("/sharedPoolContact/accept", dto, true),
        sharedPoolContactDecline: (dto: Contracts.SharedPoolActionByApplicant) => client.execute<Contracts.SharedPoolActionDetailsDTO>("/sharedPoolContact/decline", dto, true),
        sharedPoolContactOptout: (dto: Contracts.SharedPoolActionByApplicant) => client.execute<Contracts.SharedPoolActionDetailsDTO>("/sharedPoolContact/optout", dto, true),
    };
}

const client = new Client(config.API_BASE);
export default Api(client);
