import React, { useState } from "react";
import Lottie from "react-lottie";
import Fade from "../Fade";
import styles from "./styles.module.scss";
import { useShortenedLink } from "../../hooks/useShortenedLink";
import { Redirect } from "react-router-dom";

const loader = require("../../assets/lottie/loader.json");
const loaderOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
};

interface SharedPoolProps {
    readonly token: string;
}

const ShortenedLink = (props: SharedPoolProps) => {
    const { token } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [isInitialized, setIsInitialized] = useState(false);

    const details = useShortenedLink(token, () => {
        setIsLoading(false);
        setIsInitialized(true);
    });

    if (details?.urlPath) {
        return <Redirect to={details?.urlPath} />;
    }

    return (
        <div className={styles.container}>
            <Fade inProp={!isInitialized || isLoading}>
                <div className={styles.loader}>
                    <Lottie options={loaderOptions} width={72} height={72} />
                </div>
            </Fade>
        </div>
    );
};

export default ShortenedLink;
