import { useEffect, useState } from "react";
import { getInterviewCustomTimes } from "../firebase/firestore";

export function useInterviewCustomTimes(
    uid: string,
    funnelId: string,
    callback: () => void,
) {
    const [customTimes, setCustomTimes] = useState<
        With.Id<Contracts.InterviewCustomTimes>
    >();

    useEffect(() => {
        const unsubscribe = getInterviewCustomTimes(uid, funnelId).onSnapshot(
            query => {
                if (query.docs.length > 0) {
                    const times = query.docs[0].data() as Contracts.InterviewCustomTimes;

                    setCustomTimes({
                        id: query.docs[0].id,
                        ...times,
                    });

                    callback?.();
                }
            },
            err => {
                console.log(err);
            },
        );
        return unsubscribe;
    }, [uid]);

    return customTimes;
}
