import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";
import config from "./config";
import { __STAGING__, __LOCAL__ } from "./config/environment";
import "./styles/global.module.scss";

if (!__STAGING__ && !__LOCAL__) {
    Sentry.init({ dsn: config.SENTRY_CONFIG });
}
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
