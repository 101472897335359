import React, { useState } from "react";
import jwt_decode from "jwt-decode";
import { TimeSlotType } from "../../types/index";
import API from "../../api/index";
import { addDays, isBefore } from "date-fns";
import { validateSubmission } from "../../helpers/validation";
import InterviewTimes from "../../components/InterviewTimes";
import useTranslation from "../../services/language";
import styles from "./styles.module.scss";
import Button from "../../components/Button";
import { useInterviewTrialDetails } from "../../hooks/useInterviewTrialDetails";
import Scheduled from "./Scheduled";
import Header from "./Header";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { MessageProps } from "../Message";
import TrialTimes from "../TrialTimes";
import Lottie from "react-lottie";
import Fade from "../Fade";
import joinCls from "../../styles/joinClasses";
import { SchedulingType } from "@anna/shared";
import { AppRoute } from "../../routes";

const loader = require("../../assets/lottie/loader.json");
const loaderOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
};

interface CustomTimesProps {
    readonly schedulingType: SchedulingType;
    readonly token: string;
}

export interface InterviewTrialToken {
    readonly userId: string;
    readonly funnelId: string;
    readonly inviteId: string;
    readonly iat: number;
    readonly restaurantId?: string; // Only for trial
    readonly type?: Contracts.BookingType; // Only for trial
    readonly bookingId?: string; // For reschedule and cancel
}

const CustomTimes = (props: CustomTimesProps) => {
    const { schedulingType, token } = props;
    const forInterview = schedulingType === SchedulingType.Interview;

    const l = useTranslation();
    const history = useHistory();

    const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlotType>();
    const [isLoading, setIsLoading] = useState(true);
    const [isInitialized, setIsInitialized] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const canConfirm = !isLoading && selectedTimeSlot !== undefined;

    const { userId, funnelId, restaurantId, type, iat } = jwt_decode<InterviewTrialToken>(
        token,
    );
    const isExpired = isBefore(addDays(iat * 1000, 7), new Date());

    const details = useInterviewTrialDetails(schedulingType, token, () => {
        setIsLoading(false);
        setIsInitialized(true);
    });

    const sendMessage = (question: boolean = true) => {
        if (details) {
            history.push({
                pathname: AppRoute.Message,
                state: {
                    header: {
                        title: l(
                            question ? "I have a question" : "I can't make these times",
                        ),
                    },
                    messageTitle: l(
                        question
                            ? "Write us a message and we will get back\nto you on via text / email as soon as possible!"
                            : "Let us know when works best for you\nand we will try to make it work.",
                    ),
                    token,
                    restaurantLogo: details.restaurantLogo,
                } as MessageProps,
            });
        }
    };

    useEffect(() => {
        if (isSubmitted) window.scrollTo(0, 0);
    }, [isSubmitted]);

    if (isExpired) {
        return (
            <div>
                {l(
                    "Sorry, the link has expired, please text the restaurant to get a new invitation",
                )}
            </div>
        );
    }

    if (!token || !userId) {
        return <div>{l("Something went wrong")}</div>;
    }

    if (isSubmitted && details) {
        return (
            <Scheduled
                type={schedulingType}
                timeSlot={selectedTimeSlot!}
                details={details!}
                onSendMessage={sendMessage}
            />
        );
    }

    return (
        <div className={joinCls(!isInitialized && styles.containerLoading)}>
            <Fade inProp={!isInitialized}>
                <div className={styles.loader}>
                    <Lottie options={loaderOptions} width={72} height={72} />
                </div>
            </Fade>
            <Fade inProp={isInitialized}>
                <div className={styles.content}>
                    <Header
                        logo={details?.restaurantLogo ?? ""}
                        title={l("Looking forward to meet you!")}>
                        <p className={styles.subtitle}>
                            {l("Please select the time that works best for you.")}
                        </p>
                        <p className={styles.help}>
                            {l("If you have any questions for us just")}
                            <a
                                onClick={() => sendMessage()}
                                className={styles.helpAction}>
                                {l("send us a message")}
                            </a>
                        </p>
                    </Header>

                    <div className={styles.times}>
                        {forInterview ? (
                            <InterviewTimes
                                funnelId={funnelId}
                                userId={userId}
                                token={token}
                                selectedTimeSlot={selectedTimeSlot}
                                setSelectedTimeSlot={setSelectedTimeSlot}
                            />
                        ) : (
                            <TrialTimes
                                funnelId={funnelId}
                                restaurantId={restaurantId!}
                                token={token}
                                selectedTimeSlot={selectedTimeSlot}
                                setSelectedTimeSlot={setSelectedTimeSlot}
                                type={type!}
                            />
                        )}
                    </div>

                    <div className={styles.buttonWrapper}>
                        <Button
                            title={l(
                                selectedTimeSlot
                                    ? `Confirm ${schedulingType.toLowerCase()}`
                                    : "Select a timeslot",
                            )}
                            disabled={!canConfirm}
                            isLoading={isLoading}
                            className={styles.buttonConfirm}
                            onClick={async () => {
                                setIsLoading(true);
                                const result = forInterview
                                    ? await submitInterview(
                                          selectedTimeSlot!,
                                          userId,
                                          funnelId,
                                          token,
                                      )
                                    : await submitTrial(
                                          selectedTimeSlot!,
                                          userId,
                                          restaurantId!,
                                          funnelId,
                                          token,
                                          type!,
                                      );
                                if (result.isSuccess) {
                                    setIsSubmitted(true);
                                } else {
                                    alert(
                                        `Error occured while scheduling a ${schedulingType}`,
                                    );
                                }
                                setIsLoading(false);
                            }}
                        />

                        <Button
                            testId="cant-make-time"
                            title={l("I can't make these times")}
                            disabled={isLoading}
                            transparent
                            onClick={() => sendMessage(false)}
                        />
                    </div>
                </div>
            </Fade>
        </div>
    );
};

export default CustomTimes;

async function submitInterview(
    selectedTimeSlot: TimeSlotType,
    userId: string,
    funnelId: string,
    token: string,
) {
    if (!validateSubmission({ selectedTimeSlots: [selectedTimeSlot], userId, funnelId }))
        return { isSuccess: false };

    const timeSlotsToSubmit: Contracts.TimeSlotBookingType = {
        day: selectedTimeSlot.day,
        startTimeInMinutes: selectedTimeSlot.startTimeInMinutes,
        endTimeInMinutes: selectedTimeSlot.endTimeInMinutes,
        userId,
        funnelId,
    };

    return await API.confirmInterview({
        userId,
        funnelId,
        selectedTimeSlot: timeSlotsToSubmit,
        token,
    });
}

async function submitTrial(
    selectedTimeSlot: TimeSlotType,
    userId: string,
    restaurantId: string,
    funnelId: string,
    token: string,
    type: Contracts.BookingType,
) {
    if (!selectedTimeSlot) {
        alert("Please select at least one slot");
        return { isSuccess: false };
    }

    if (!restaurantId || !funnelId) {
        alert("Something went wrong, please try again");
        return { isSuccess: false };
    }

    const timeSlotsToSubmit: Contracts.TrialTimeSlotBookingType = {
        day: selectedTimeSlot.day,
        startTimeInMinutes: selectedTimeSlot.startTimeInMinutes,
        endTimeInMinutes: selectedTimeSlot.endTimeInMinutes,
        restaurantId,
        funnelId,
    };

    return await API.confirmTrial({
        userId,
        restaurantId,
        funnelId,
        selectedTimeSlot: timeSlotsToSubmit,
        token,
        type,
    });
}
