import {
    TypeformReferences,
    IMapTypeform,
    HireformReferences,
    IMapHireform,
} from "../enums/typeform";
import { ApplicationPositionType } from "../enums";
import { mapVisaToEnum } from "./visaType";
import { IFunnelUserMetaData } from "../../types/Funnels";
import { IResponse, Answer, HireAnswer } from "../../types/Webhooks";
import {
    ApplicationTypeValue,
    TypeformWorkHours,
    translateText,
    TranslationKeys,
} from "@anna/shared";

export enum DanishPositionValue {
    Medarbejder = "Medarbejder",
    Manager = "Manager",
    KøkkenAssistent = "Køkkenassistent",
}
export enum DanishWorkhoursValue {
    UNDER_10_HOURS = "5-10 timer",
    UNDER_20_HOURS = "10-20 timer",
    UNDER_30_HOURS = "20-30 timer",
    UNDER_40_HOURS = "30-40 timer",
}
export enum WorkhoursValue {
    UNDER_10_HOURS = "5-10 hours",
    UNDER_20_HOURS = "10-20 hours",
    UNDER_30_HOURS = "20-30 hours",
    UNDER_40_HOURS = "30-40 hours",
}

// TODO: use later
export function danishPositionsMapper(position: {
    label: string;
}): { label: Contracts.ApplicationTypeValue } {
    switch (position?.label) {
        case DanishPositionValue.Medarbejder:
            return { label: ApplicationTypeValue.CrewMember };
        case DanishPositionValue.Manager:
            return { label: ApplicationTypeValue.Management };
        case DanishPositionValue.KøkkenAssistent:
            return { label: ApplicationTypeValue.Kitchen };
        default:
            return { label: position?.label?.trim() } as {
                label: Contracts.ApplicationTypeValue;
            }; // casting to this type so everyone is happy with the hack
    }
}
export function danishWorkhoursMapper(workHours: {
    label: TypeformWorkHours | WorkhoursValue | DanishWorkhoursValue;
}): { label: TypeformWorkHours | WorkhoursValue } {
    switch (workHours?.label) {
        case DanishWorkhoursValue.UNDER_10_HOURS:
            return { label: WorkhoursValue.UNDER_10_HOURS };
        case DanishWorkhoursValue.UNDER_20_HOURS:
            return { label: WorkhoursValue.UNDER_20_HOURS };
        case DanishWorkhoursValue.UNDER_30_HOURS:
            return { label: WorkhoursValue.UNDER_30_HOURS };
        case DanishWorkhoursValue.UNDER_40_HOURS:
            return { label: WorkhoursValue.UNDER_40_HOURS };
        default:
            return { label: workHours.label?.trim() } as {
                label: TypeformWorkHours | WorkhoursValue;
            };
    }
}

export function mapPosition(position: {
    label: string;
}): { label: Contracts.ApplicationTypeValue } {
    // mapping U18 option from typeform text
    if (position?.label === ApplicationPositionType.under18)
        return { label: ApplicationTypeValue.Under18 };
    return { label: position?.label?.trim() } as {
        label: Contracts.ApplicationTypeValue;
    };
}

function getLanguages(content: { label?: string; labels?: string[] }): string[] {
    if (!Array.isArray(content.labels)) {
        return content.label ? [content.label] : [];
    }
    return content.labels.map((language: string) =>
        translateText(TranslationKeys.APPLICATION_SPOKEN_LANGUAGES, language),
    );
}

export function mapWorkhours(workHours: {
    label: TypeformWorkHours | WorkhoursValue | DanishWorkhoursValue;
}): { label: TypeformWorkHours | WorkhoursValue } {
    return danishWorkhoursMapper(workHours);
}

export const extractMappedTypeformContent = (typeform: IMapTypeform) => {
    // For U18 positions, there are no workhours defined in the typeform
    const workHours =
        typeform?.application_position?.content?.label === ApplicationTypeValue.Under18
            ? TypeformWorkHours.U18
            : typeform?.application_workhours?.content?.label || null;
    return {
        firstName: typeform?.application_firstname?.content,
        lastName: typeform?.application_lastname?.content,
        email: typeform?.application_email?.content,
        position: typeform?.application_position?.content?.label,
        positionBoh: typeform?.application_position_boh?.content?.label,
        positionFoh: typeform?.application_position_foh?.content?.label,
        positionAdmin: typeform?.application_position_admin?.content?.label,
        positionDetail: typeform?.application_position_detail?.content?.label,
        positionInternDuration: typeform?.application_internship_duration?.content?.label,
        workHours,
        visaType: typeform?.application_visa_type?.content?.label,
        applicantPhoneNumber: typeform?.application_phone_number?.content,
        profilePicture: typeform?.application_profile_picture?.content,
    };
};

const countryPickerValue = {
    sweden: "Sweden",
};

/**
 * Convenience function to extract user meta data from typeform data
 *
 * @param mappedTypeformData
 */
export const getUserMetaTypeform = (
    mappedTypeformData: IMapTypeform,
): IFunnelUserMetaData => {
    const {
        email,
        firstName,
        position,
        positionBoh,
        positionFoh,
        positionAdmin,
        positionInternDuration,
        positionDetail,
        workHours,
        visaType,
        profilePicture,
    } = extractMappedTypeformContent(mappedTypeformData);
    // TODO: HACK for hooked sweden
    const isSweden =
        mappedTypeformData?.application_restaurant_country?.content?.label ===
        countryPickerValue.sweden;
    // TODO: HACK from hooked sweden (adding flag)
    const lastName = isSweden
        ? `${mappedTypeformData.application_lastname.content}🇸🇪`
        : mappedTypeformData.application_lastname.content;
    return {
        email,
        firstName: firstName,
        image: profilePicture || null,
        lastName: lastName,
        position,
        positionBoh: positionBoh || "",
        positionFoh: positionFoh || "",
        positionAdmin: positionAdmin || "",
        positionInternDuration: positionInternDuration || "",
        positionDetail: positionDetail || "",
        workHours: workHours as TypeformWorkHours,
        visaType: mapVisaToEnum(visaType),
    };
};

export function mapHireform(answers: IResponse["answers"]) {
    const object = answers.reduce((acc: Partial<IMapHireform>, answer: HireAnswer) => {
        switch (answer.field.ref) {
            case HireformReferences.name:
                return {
                    ...acc,
                    [HireformReferences.name]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case HireformReferences.address:
                return {
                    ...acc,
                    [HireformReferences.address]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case HireformReferences.cpr_option:
                return {
                    ...acc,
                    [HireformReferences.cpr_option]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case HireformReferences.cpr:
                return {
                    ...acc,
                    [HireformReferences.cpr]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case HireformReferences.cpr_workcontract:
                return {
                    ...acc,
                    [HireformReferences.cpr_workcontract]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case HireformReferences.account_number:
                return {
                    ...acc,
                    [HireformReferences.account_number]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case HireformReferences.account_reg_number:
                return {
                    ...acc,
                    [HireformReferences.account_reg_number]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case HireformReferences.working_visa:
                return {
                    ...acc,
                    [HireformReferences.working_visa]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case HireformReferences.visa_file:
                return {
                    ...acc,
                    [HireformReferences.visa_file]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };

            default:
                return {
                    ...acc,
                    [answer.field.ref]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
        }
    }, {}) as IMapHireform;
    return object;
}

export function mapTypeform(answers: IResponse["answers"]) {
    const object = answers.reduce((acc: Partial<IMapTypeform>, answer: Answer) => {
        switch (answer.field.ref) {
            // TODO: HACK hooked
            case TypeformReferences.application_restaurant_name:
                return {
                    ...acc,
                    [TypeformReferences.application_restaurant_name]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            // TODO: HACK hooked
            case TypeformReferences.application_restaurant_country:
                return {
                    ...acc,
                    [TypeformReferences.application_restaurant_country]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_firstname:
                return {
                    ...acc,
                    [TypeformReferences.application_firstname]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_lastname:
                return {
                    ...acc,
                    [TypeformReferences.application_lastname]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_motivation:
                return {
                    ...acc,
                    [TypeformReferences.application_motivation]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_self_intro:
                return {
                    ...acc,
                    [TypeformReferences.application_self_intro]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_position:
                return {
                    ...acc,
                    [TypeformReferences.application_position]: {
                        fieldType: answer.field.type,
                        content: mapPosition({
                            label: translateText(
                                TranslationKeys.APPLICATION_POSITION,
                                answer[answer.type]?.label,
                            ),
                        }),
                    },
                };
            case TypeformReferences.application_internship_duration:
                return {
                    ...acc,
                    [TypeformReferences.application_internship_duration]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                    /**
                     * When application_internship_duration is selected,
                     * we skip the application_workhours question in the
                     * typeform. This means it is an intern we deal with
                     * therefore we map the application_internship_duration to
                     * application_workhours
                     */
                    [TypeformReferences.application_workhours]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_position_detail:
                return {
                    ...acc,
                    [TypeformReferences.application_position_detail]: {
                        fieldType: answer.field.type,
                        content: mapPosition({
                            label: translateText(
                                TranslationKeys.APPLICATION_POSITION_DETAIL,
                                answer[answer.type]?.label,
                            ),
                        }),
                    },
                };
            case TypeformReferences.application_position_bakery:
                return {
                    ...acc,
                    [TypeformReferences.application_position_detail]: {
                        fieldType: answer.field.type,
                        content: mapPosition({
                            label: translateText(
                                TranslationKeys.APPLICATION_POSITION_DETAIL,
                                answer[answer.type]?.label,
                            ),
                        }),
                    },
                };
            case TypeformReferences.application_position_pastry:
                return {
                    ...acc,
                    [TypeformReferences.application_position_detail]: {
                        fieldType: answer.field.type,
                        content: mapPosition({
                            label: translateText(
                                TranslationKeys.APPLICATION_POSITION_DETAIL,
                                answer[answer.type]?.label,
                            ),
                        }),
                    },
                };
            case TypeformReferences.application_position_cleaning:
                return {
                    ...acc,
                    [TypeformReferences.application_position_detail]: {
                        fieldType: answer.field.type,
                        content: mapPosition({
                            label: translateText(
                                TranslationKeys.APPLICATION_POSITION_DETAIL,
                                answer[answer.type]?.label,
                            ),
                        }),
                    },
                };
            case TypeformReferences.application_position_retail:
                return {
                    ...acc,
                    [TypeformReferences.application_position_detail]: {
                        fieldType: answer.field.type,
                        content: mapPosition({
                            label: translateText(
                                TranslationKeys.APPLICATION_POSITION_DETAIL,
                                answer[answer.type]?.label,
                            ),
                        }),
                    },
                };
            case TypeformReferences.application_position_roastery:
                return {
                    ...acc,
                    [TypeformReferences.application_position_detail]: {
                        fieldType: answer.field.type,
                        content: mapPosition({
                            label: translateText(
                                TranslationKeys.APPLICATION_POSITION_DETAIL,
                                answer[answer.type]?.label,
                            ),
                        }),
                    },
                };
            case TypeformReferences.application_position_coffeebar:
                return {
                    ...acc,
                    [TypeformReferences.application_position_detail]: {
                        fieldType: answer.field.type,
                        content: mapPosition({
                            label: translateText(
                                TranslationKeys.APPLICATION_POSITION_DETAIL,
                                answer[answer.type]?.label,
                            ),
                        }),
                    },
                };
            case TypeformReferences.application_position_u18:
                return {
                    ...acc,
                    [TypeformReferences.application_position_detail]: {
                        fieldType: answer.field.type,
                        content: mapPosition({
                            label: translateText(
                                TranslationKeys.APPLICATION_POSITION_DETAIL,
                                answer[answer.type]?.label,
                            ),
                        }),
                    },
                };
            case TypeformReferences.application_position_boh:
                return {
                    ...acc,
                    [TypeformReferences.application_position_boh]: {
                        fieldType: answer.field.type,
                        content: mapPosition({
                            label: translateText(
                                TranslationKeys.APPLICATION_POSITION_DETAIL,
                                answer[answer.type]?.label,
                            ),
                        }),
                    },
                    [TypeformReferences.application_position_detail]: {
                        fieldType: answer.field.type,
                        content: mapPosition({
                            label: translateText(
                                TranslationKeys.APPLICATION_POSITION_DETAIL,
                                answer[answer.type]?.label,
                            ),
                        }),
                    },
                };
            case TypeformReferences.application_position_foh:
                return {
                    ...acc,
                    [TypeformReferences.application_position_foh]: {
                        fieldType: answer.field.type,
                        content: mapPosition({
                            label: translateText(
                                TranslationKeys.APPLICATION_POSITION_DETAIL,
                                answer[answer.type]?.label,
                            ),
                        }),
                    },
                    [TypeformReferences.application_position_detail]: {
                        fieldType: answer.field.type,
                        content: mapPosition({
                            label: translateText(
                                TranslationKeys.APPLICATION_POSITION_DETAIL,
                                answer[answer.type]?.label,
                            ),
                        }),
                    },
                };
            case TypeformReferences.application_position_admin:
                return {
                    ...acc,
                    [TypeformReferences.application_position_admin]: {
                        fieldType: answer.field.type,
                        content: mapPosition({
                            label: translateText(
                                TranslationKeys.APPLICATION_POSITION_DETAIL,
                                answer[answer.type]?.label,
                            ),
                        }),
                    },
                    [TypeformReferences.application_position_detail]: {
                        fieldType: answer.field.type,
                        content: mapPosition({
                            label: translateText(
                                TranslationKeys.APPLICATION_POSITION_DETAIL,
                                answer[answer.type]?.label,
                            ),
                        }),
                    },
                };
            case TypeformReferences.application_workhours:
            // TODO: HACK hooked
            case TypeformReferences.application_workhours_hooked_se:
                return {
                    ...acc,
                    [TypeformReferences.application_workhours]: {
                        fieldType: answer.field.type,
                        content: mapWorkhours({
                            label: translateText(
                                TranslationKeys.APPLICATION_WORKHOURS,
                                answer[answer.type]?.label || answer[answer.type]?.other,
                            ) as
                                | TypeformWorkHours
                                | WorkhoursValue
                                | DanishWorkhoursValue,
                        }),
                    },
                };
            case TypeformReferences.application_availability_year:
                return {
                    ...acc,
                    [TypeformReferences.application_availability_year]: {
                        fieldType: answer.field.type,
                        content: {
                            label: translateText(
                                TranslationKeys.APPLICATION_AVAILABILITY_YEAR,
                                answer[answer.type]?.label,
                            ),
                        },
                    },
                };
            case TypeformReferences.application_start_date:
                return {
                    ...acc,
                    [TypeformReferences.application_start_date]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_visa_type:
                return {
                    ...acc,
                    [TypeformReferences.application_visa_type]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_visa_type_holiday:
                return {
                    ...acc,
                    [TypeformReferences.application_visa_type_holiday]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_work_experience:
                return {
                    ...acc,
                    [TypeformReferences.application_work_experience]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_spoken_languages:
                return {
                    ...acc,
                    [TypeformReferences.application_spoken_languages]: {
                        fieldType: answer.field.type,
                        content: {
                            labels: getLanguages(answer[answer.type]),
                        },
                    },
                };
            case TypeformReferences.application_cv:
                return {
                    ...acc,
                    [TypeformReferences.application_cv]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_profile_picture:
                return {
                    ...acc,
                    [TypeformReferences.application_profile_picture]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_phone_number:
            // TODO: HACK hooked
            case TypeformReferences.application_phone_number_hooked_se:
                return {
                    ...acc,
                    [TypeformReferences.application_phone_number]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_email:
                return {
                    ...acc,
                    [TypeformReferences.application_email]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type].toLowerCase(),
                    },
                };
            case TypeformReferences.application_shared_pool_consent:
                return {
                    ...acc,
                    [TypeformReferences.application_shared_pool_consent]: {
                        fieldType: answer.field.type,
                        content: {
                            label: translateText(
                                TranslationKeys.APPLICATION_SHARED_POOL_CONSENT,
                                answer[answer.type]?.label,
                            ),
                        },
                    },
                };
            case TypeformReferences.application_terms_consent:
                return {
                    ...acc,
                    [TypeformReferences.application_terms_consent]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_internship_length:
                return {
                    ...acc,
                    [TypeformReferences.application_internship_length]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_wine_knowledge:
                return {
                    ...acc,
                    [TypeformReferences.application_wine_knowledge]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_management_knowledge:
                return {
                    ...acc,
                    [TypeformReferences.application_management_knowledge]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_drinks_knowledge:
                return {
                    ...acc,
                    [TypeformReferences.application_drinks_knowledge]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_coffee_knowledge:
                return {
                    ...acc,
                    [TypeformReferences.application_coffee_knowledge]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_cpr:
                return {
                    ...acc,
                    [TypeformReferences.application_cpr]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_workexperience_total:
                return {
                    ...acc,
                    [TypeformReferences.application_workexperience_total]: {
                        fieldType: answer.field.type,
                        content: {
                            label: translateText(
                                TranslationKeys.APPLICATION_WORKEXPERIENCE_TOTAL,
                                answer[answer.type]?.label,
                            ),
                        },
                    },
                };
            case TypeformReferences.application_shift_availability:
                return {
                    ...acc,
                    [TypeformReferences.application_shift_availability]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_residence_country:
                return {
                    ...acc,
                    [TypeformReferences.application_residence_country]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_nationality:
                return {
                    ...acc,
                    [TypeformReferences.application_nationality]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_passport_expiration:
                return {
                    ...acc,
                    [TypeformReferences.application_passport_expiration]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_education_chef:
                return {
                    ...acc,
                    [TypeformReferences.application_education_chef]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_hospitality_diploma:
                return {
                    ...acc,
                    [TypeformReferences.application_hospitality_diploma]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_internship_period_first_preference:
                return {
                    ...acc,
                    [TypeformReferences.application_internship_period_first_preference]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_internship_period_second_preference:
                return {
                    ...acc,
                    [TypeformReferences.application_internship_period_second_preference]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_birthdate:
                return {
                    ...acc,
                    [TypeformReferences.application_birthdate]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_address:
                return {
                    ...acc,
                    [TypeformReferences.application_address]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_gender:
                return {
                    ...acc,
                    [TypeformReferences.application_gender]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_u18_drivercheck:
                return {
                    ...acc,
                    [TypeformReferences.application_u18_drivercheck]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
            case TypeformReferences.application_location:
                return {
                    ...acc,
                    [TypeformReferences.application_location]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };

            default:
                return {
                    ...acc,
                    [answer.field.ref]: {
                        fieldType: answer.field.type,
                        content: answer[answer.type],
                    },
                };
        }
    }, {}) as IMapTypeform;
    return object;
}
