import { useEffect, useState } from "react";
import { getShortenedUrlDetails } from "../firebase/firestore";

export function useShortenedLink(token: string, callback?: () => void) {
    const [
        shortenedUrlDetails,
        setShortenedUrlDetails,
    ] = useState<Contracts.ShortenedUrlDetails | null>(null);

    useEffect(() => {
        const unsubscribe = getShortenedUrlDetails(token).onSnapshot(
            doc => {
                setShortenedUrlDetails(doc.data() as Contracts.ShortenedUrlDetails);
                callback?.();
            },
            err => {
                console.error(err);
            },
        );
        return unsubscribe;
    }, [token]);

    return shortenedUrlDetails;
}
