import { useEffect, useState } from "react";
import API from "../api";
import { SchedulingType } from "@anna/shared";

export function useInterviewTrialDetails(
    type: SchedulingType,
    token: string,
    callback?: () => void,
) {
    const [interviewDetails, setInterviewDetails] = useState<
        Contracts.InterviewTrialDetailsDTO
    >();

    useEffect(() => {
        const fetchRestaurant = async () => {
            const dto = { token };
            const response =
                type === SchedulingType.Interview
                    ? await API.interviewDetails(dto)
                    : await API.trialDetails(dto);
            if (response.isSuccess) {
                setInterviewDetails(response.result);
                callback?.();
            }
        };
        fetchRestaurant();
    }, [token]);

    return interviewDetails;
}
