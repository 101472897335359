import React from "react";
import joinCls from "../../styles/joinClasses";
import styles from "./styles.module.scss";

interface DividerProps {
    readonly className?: string;
}

export function Divider(props: DividerProps) {
    return <div className={joinCls(props.className, styles.divider)} />;
}
