import * as firebase from "firebase";
import { useEffect, useState } from "react";
import { getBookingById } from "../firebase/firestore";
import { Booking } from "../types/index";

export function useBooking(bookingId: string) {
    const [booking, setBooking] = useState<Booking>();

    useEffect(() => {
        const fetchBooking = async () => {
            const doc = await getBookingById(bookingId).get();
            if (doc.data()) {
                const { startTime, endTime } = doc.data() as With.DateAs<
                    Booking,
                    firebase.firestore.Timestamp
                >;
                setBooking({
                    id: doc.id,
                    startTime: startTime.toDate(),
                    endTime: endTime.toDate(),
                } as Booking);
            }
        };
        fetchBooking();
    }, [bookingId]);

    return booking;
}
