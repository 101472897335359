import { format } from "date-fns";
import { TimeSlotType } from "../types/index";
import { enUS } from "date-fns/locale";

export const locale = { locale: enUS };
export const isSelectedSlotMatching = ({
    timeSlot,
    selectedTimeSlot,
}: {
    timeSlot: TimeSlotType;
    selectedTimeSlot: TimeSlotType | null;
}) => {
    if (!timeSlot || !selectedTimeSlot) return false;

    return (
        timeSlot?.day === selectedTimeSlot?.day &&
        timeSlot?.startTimeInMinutes === selectedTimeSlot?.startTimeInMinutes
    );
};

export function shortLocalDateFormat(date: Date) {
    return format(date, "LLL dd", locale);
}
