import React from "react";
import Lottie from "react-lottie";
import loader from "../../assets/lottie/loader.json";

const loaderOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
};

interface Props {
    height?: number;
    width?: number;
}

export default function ({ height = 72, width = 72 }: Props) {
    return <Lottie options={loaderOptions} width={width} height={height} />;
}
