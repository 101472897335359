import React from "react";
import { StyleSheet, View } from "react-native";
import { StyleGuide } from "../../constants/StyleGuide";

export function MiniSpace() {
    return <View style={styles.mini} />;
}
export function SmallSpace() {
    return <View style={styles.small} />;
}
export function BaseSpace() {
    return <View style={styles.base} />;
}
export function MediumSpace() {
    return <View style={styles.medium} />;
}
export function LargeSpace() {
    return <View style={styles.large} />;
}
export function XLargeSpace() {
    return <View style={styles.xlarge} />;
}

const styles = StyleSheet.create({
    mini: { paddingTop: StyleGuide.spacing.mini },
    small: { paddingTop: StyleGuide.spacing.small },
    base: { paddingTop: StyleGuide.spacing.base },
    medium: { paddingTop: StyleGuide.spacing.medium },
    large: { paddingTop: StyleGuide.spacing.large },
    xlarge: { paddingTop: StyleGuide.spacing.xlarge },
});
