import React, { useState, useEffect } from "react";
import useTranslation from "../../services/language";
import jwt_decode from "jwt-decode";
import { InterviewTrialToken } from "../CustomTimes";
import { getTokenDetails } from "../../helpers/token";
import { useInterviewTrialDetails } from "../../hooks/useInterviewTrialDetails";
import Message from "../Message";
import { useBooking } from "../../hooks/useBooking";
import { Dates, SchedulingType } from "@anna/shared";
import styles from "./styles.module.scss";
import { addLineBreaks } from "../../helpers/text";
import API from "../../api";
import Lottie from "react-lottie";
import Fade from "../Fade";

const loader = require("../../assets/lottie/loader.json");
const loaderOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
};

interface RescheduleProps {
    readonly schedulingType: SchedulingType;
    readonly token: string;
}

export default function Reschedule(props: RescheduleProps) {
    const { schedulingType, token } = props;
    const forInterview = schedulingType === SchedulingType.Interview;

    const l = useTranslation();
    const [isInitialized, setIsInitialized] = useState(false);

    const { userId, funnelId, bookingId, restaurantId, type } = jwt_decode<
        InterviewTrialToken
    >(token);

    const { isExpired } = getTokenDetails({ token });
    const details = useInterviewTrialDetails(schedulingType, token);
    const booking = useBooking(bookingId!);

    useEffect(() => {
        if (details && booking && !isInitialized) {
            setIsInitialized(true);
        }
    }, [details, booking]);

    if (isExpired) {
        return (
            <div>
                {l(
                    "Sorry, the link has expired, please text the restaurant to get a new invitation",
                )}
            </div>
        );
    }

    if (!token || !userId) {
        return <div>{l("Something went wrong")}</div>;
    }

    const userName = details?.userName ?? "";
    const bookingDate = booking ? Dates.shortLocalDateFormat(booking.startTime) : "";
    const bookingTime = booking ? Dates.dateToTime(booking.startTime) : "";

    return (
        <div className={styles.container}>
            <Fade inProp={!isInitialized}>
                <div className={styles.loader}>
                    <Lottie options={loaderOptions} width={72} height={72} />
                </div>
            </Fade>
            <Fade inProp={isInitialized}>
                <Message
                    header={{
                        title: l(`Reschedule ${schedulingType}`),
                        subtitle: l(`${userName} on ${bookingDate} at ${bookingTime}`),
                    }}
                    messageTitle={l(
                        "Let us know why you can't make it and what days you are available in case we can reschedule",
                    )}
                    token={token}
                    restaurantLogo={details?.restaurantLogo ?? ""}
                    additionalInformation={
                        <p className={styles.additionalInformation}>
                            {addLineBreaks(
                                l(
                                    `When you request a reschedule,\nyour current ${schedulingType} slot will be `,
                                ),
                            )}
                            <b>{l("cancelled")}</b>.
                        </p>
                    }
                    messageSent={{
                        title: l("Reschedule message sent!"),
                        subtitle: l(
                            `We will get back to you as soon as possible to see if we can find a good time that works.\n\nYour previously scheduled ${schedulingType} slot with ${userName} on ${bookingDate} at ${bookingTime} has been cancelled.`,
                        ),
                    }}
                    buttonTitle={l("Request reschedule")}
                    customSendRequest={async message => {
                        const baseDto = {
                            userId,
                            funnelId,
                            bookingId: bookingId!,
                            message,
                            token,
                        };

                        return forInterview
                            ? await API.rescheduleInterview(baseDto)
                            : await API.rescheduleTrial({
                                  ...baseDto,
                                  restaurantId: restaurantId!,
                                  type,
                              });
                    }}
                />
            </Fade>
        </div>
    );
}
