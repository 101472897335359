import React from "react";
import { TimeSlotType } from "../../../types";
import styles from "./styles.module.scss";
import Header from "../Header";
import useTranslation from "../../../services/language";
import { capitilize, Dates, SchedulingType, DateTimeConversion } from "@anna/shared";

interface ScheduledProps {
    readonly type: SchedulingType;
    readonly timeSlot: TimeSlotType;
    readonly details: Contracts.InterviewTrialDetailsDTO;
    readonly onSendMessage: () => void;
}

export default function Scheduled(props: ScheduledProps) {
    const l = useTranslation();
    const { type, timeSlot, details, onSendMessage } = props;

    return (
        <div className={styles.container}>
            <Header logo={details.restaurantLogo} title={l("See you soon!")}>
                <p className={styles.subtitle}>{l(`${capitilize(type)} details:`)}</p>
                <p className={styles.date}>
                    <span>{Dates.shortLocalDateFormat(timeSlot.startTime)}</span>
                    {" at "}
                    <span>
                        {DateTimeConversion.minutesToHoursAndMinutes(
                            timeSlot.startTimeInMinutes,
                        )}
                    </span>
                </p>
                <p className={styles.address}>{details.location.address}</p>
            </Header>

            <p className={styles.help}>
                {l("If you have any questions for us just")}
                <a onClick={onSendMessage} className={styles.helpAction}>
                    {l("send us a message")}
                </a>
            </p>
        </div>
    );
}
