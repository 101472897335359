import { useEffect, useState } from "react";
import API from "../api";

export function useSharedPoolApplicantAccept(token: string): {
    isLoading: boolean;
    data: Contracts.SharedPoolActionDetailsDTO | undefined;
} {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<Contracts.SharedPoolActionDetailsDTO>();

    useEffect(() => {
        const updateApplicantResponse = async () => {
            !isLoading && setIsLoading(true);
            const dto = { token };
            try {
                const response = await API.sharedPoolContactAccept(dto);
                if (response?.isSuccess) {
                    setData(response.result);
                }
            } catch (error) {
                // @ts-ignore
                throw new Error(error);
            } finally {
                setIsLoading(false);
            }
        };
        updateApplicantResponse();
    }, [token]);

    return { isLoading, data };
}

export function useSharedPoolApplicantDecline(token: string): {
    isLoading: boolean;
    data: Contracts.SharedPoolActionDetailsDTO | undefined;
} {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<Contracts.SharedPoolActionDetailsDTO>();

    useEffect(() => {
        const updateApplicantResponse = async () => {
            const dto = { token };
            try {
                const response = await API.sharedPoolContactDecline(dto);
                if (response?.isSuccess) {
                    setData(response.result);
                }
            } catch (error) {
                // @ts-ignore
                throw new Error(error);
            } finally {
                setIsLoading(false);
            }
        };
        updateApplicantResponse();
    }, [token]);

    return { isLoading, data };
}

export function useSharedPoolApplicantOptout(token: string): {
    isLoading: boolean;
    data: Contracts.SharedPoolActionDetailsDTO | undefined;
} {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<Contracts.SharedPoolActionDetailsDTO>();
    useEffect(() => {
        const updateApplicantResponse = async () => {
            const dto = { token };
            try {
                const response = await API.sharedPoolContactOptout(dto);
                if (response?.isSuccess) {
                    setData(response.result);
                }
            } catch (error) {
                // @ts-ignore
                throw new Error(error);
            } finally {
                setIsLoading(false);
            }
        };
        updateApplicantResponse();
    }, [token]);

    return { isLoading, data };
}
