import React, { useState, useEffect } from "react";
import useTranslation from "../../services/language";
import jwt_decode from "jwt-decode";
import { InterviewTrialToken } from "../CustomTimes";
import { getTokenDetails } from "../../helpers/token";
import { useInterviewTrialDetails } from "../../hooks/useInterviewTrialDetails";
import Message from "../Message";
import { useBooking } from "../../hooks/useBooking";
import { Dates, SchedulingType } from "@anna/shared";
import styles from "./styles.module.scss";
import API from "../../api";
import Lottie from "react-lottie";
import Fade from "../Fade";

const loader = require("../../assets/lottie/loader.json");
const loaderOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
};

interface CancelProps {
    readonly schedulingType: SchedulingType;
    readonly token: string;
}

export default function Cancel(props: CancelProps) {
    const { schedulingType, token } = props;
    const forInterview = schedulingType === SchedulingType.Interview;

    const l = useTranslation();
    const [isInitialized, setIsInitialized] = useState(false);

    const { userId, bookingId } = jwt_decode<InterviewTrialToken>(token);

    const { isExpired } = getTokenDetails({ token });

    const details = useInterviewTrialDetails(schedulingType, token);
    const booking = useBooking(bookingId!);

    useEffect(() => {
        if (details && booking && !isInitialized) {
            setIsInitialized(true);
        }
    }, [details, booking]);

    if (isExpired) {
        return (
            <div>
                {l(
                    "Sorry, the link has expired, please text the restaurant to get a new invitation",
                )}
            </div>
        );
    }

    if (!token || !userId) {
        return <div>{l("Something went wrong")}</div>;
    }

    const userName = details?.userName ?? "";
    const bookingDate = booking ? Dates.shortLocalDateFormat(booking.startTime) : "";
    const bookingTime = booking ? Dates.dateToTime(booking.startTime) : "";

    return (
        <div className={styles.container}>
            <Fade inProp={!isInitialized}>
                <div className={styles.loader}>
                    <Lottie options={loaderOptions} width={72} height={72} />
                </div>
            </Fade>
            <Fade inProp={isInitialized}>
                <Message
                    header={{
                        title: l(`Cancel ${schedulingType}`),
                        subtitle: l(`${userName} on ${bookingDate} at ${bookingTime}`),
                    }}
                    messageTitle={l("Sad to hear, why do you need to cancel?")}
                    token={token}
                    restaurantLogo={details?.restaurantLogo ?? ""}
                    messageSent={{
                        title: l("Interview canceled"),
                        subtitle: l(
                            `Your ${schedulingType} with ${userName} on ${bookingDate} at ${bookingTime} has been cancelled.`,
                        ),
                    }}
                    buttonTitle={l(`Cancel ${schedulingType}`)}
                    customSendRequest={async message => {
                        const dto = {
                            bookingId: bookingId!,
                            message,
                            token,
                        };

                        return forInterview
                            ? await API.cancelInterview(dto)
                            : await API.cancelTrial(dto);
                    }}
                />
            </Fade>
        </div>
    );
}
