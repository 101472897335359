import { useEffect, useState } from "react";
import { getFunnelTimePreferences } from "../firebase/firestore";

const dateStringRegex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
interface ICustomPreference {
    [dateString: string]: {
        startTimeInMinutes: number;
        endTimeInMinutes: number;
    };
}
export function useCustomTrialPreferences(
    funnelId: string,
    type: Contracts.BookingType,
    callback: () => void,
): ICustomPreference {
    const [customPreference, setCustomPreference] = useState<ICustomPreference>({});

    useEffect(() => {
        const unsubscribe = getFunnelTimePreferences(funnelId, type).onSnapshot(
            query => {
                if (query.docs.length > 0) {
                    const preference = query.docs[0].data();
                    setCustomPreference(
                        Object.keys(preference)
                            .filter(date => date.match(dateStringRegex))
                            .reduce(
                                (acc, key: string) => ({
                                    ...acc,
                                    [key]: preference[key],
                                }),
                                {},
                            ),
                    );
                }
                callback();
            },
            err => {
                console.log(err);
            },
        );
        return unsubscribe;
    }, [funnelId, type]);

    return customPreference;
}
