import { ApplicationState, ActivityType } from "@anna/shared";

export enum HttpMethods {
    POST = "POST",
    GET = "GET",
    PUT = "PUT",
    DELETE = "DELETE",
}

export enum PhoneNumberIsoCountryCode {
    Denmark = "DK",
}

export enum ConversationState {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
}

export enum FirestoreBatchOperations {
    SIZE = 400, // Use this to ensure we don't go over the limit
    MAX = 500, // Max limit to the number of operations in each batch
}

export enum FirestoreCollections {
    ACTIVITY = "activity",
    ANALYTICS = "analytics",
    ANALYTICS_APPLICANT = "applicant",
    APPLICANTS = "applicants",
    APPLICATIONS = "applications",
    BOOKINGS = "bookings",
    CALENDAR_DETAILS = "calendars",
    CONTRACTS = "contracts",
    CONVERSATIONS = "conversations",
    ENRICHED_APPLICATION_DATA = "enrichedData",
    FAULTY_NOTIFICATION_TOKENS = "faultyNotificationTokens",
    FUNNELS = "funnels",
    FUNNEL_TRIAL_PREFERENCES = "trialPreferences",
    INBOUND_EMAIL_DUMPS = "inboundEmailDumps",
    EMAIL_EVENTS = "emailEvents",
    INTEGRATIONS = "integrations",
    INTERVIEW_INVITES = "interviewInvites",
    INTERVIEW_CUSTOM_TIMES = "interviewCustomTimes",
    INTERVIEW_SUGGESTED_TIME_SLOTS = "interviewSuggestedTimeSlots",
    INTERVIEW_LOCATIONS = "interviewLocations",
    JOBS = "jobs",
    NOTIFICATIONS = "notifications",
    PHONE_NUMBERS = "phoneNumbers",
    RECRUITMENT = "recruitment",
    REJECTION_QUEUE = "rejectionQueue",
    RESTAURANTS = "restaurants",
    RESTAURANT_GROUPS = "restaurantGroups",
    RESTAURANT_INTEGRATIONS = "integrations",
    RESTAURANT_POSITIONS = "positions",
    RESTAURANT_SHARED_POOL_FAVOURITES = "sharedPoolFavourites",
    RESTAURANT_SHARED_POOL_INTRODUCTIONS = "sharedPoolIntroductions",
    RESTAURANT_SHARED_POOL_REMOVED = "sharedPoolRemoved",
    RESTAURANT_TEAM_ACTIVITY = "teamActivity",
    RESTAURANT_TEMPLATES = "templates",
    RESTAURANT_TRIAL_PREFERENCES = "trialPreferences",
    SHARED_POOL = "internalSharedTalentPool",
    SHARED_POOL_APPLICATIONS = "sharedPoolApplications",
    SHARED_POOL_DAILY_REPORTS = "dailyReports",
    SHARED_POOL_REPORTS = "sharedPoolReports",
    SHARED_POOL_USER_TESTS = "userTests",
    SHORTENED_URLS = "shortenedUrls",
    TRIAL_INVITES = "trialInvites",
    TRIAL_SUGGESTED_TIME_SLOTS = "trialSuggestedTimeSlots",
    TRIAL_LOCATIONS = "trialLocations",
    TWILIO_RETRY_MESSAGES = "twilioRetryMessages",
    TWILIO_WHITELIST = "twilioWhitelist",
    USERS = "users",
    USER_ACTIVITY = "userActivity",
    USER_INTERVIEW_PREFERENCES = "interviewPreferences",
    USER_PREFERENCES = "userPreferences",
    USER_RECRUITMENT = "userRecruitment",
    INVALIDATED_TOKENS = "invalidatedTokens",
}

export enum ApplicationPositionType {
    under18 = "U18 team member (select if between 16-18 years old)",
}

export enum AnalyticsEventTypes {
    // BE (actions + messages)

    ACTION_CHAT_MESSAGE_SENT = "ACTION_CHAT_MESSAGE_SENT", // DONE
    ACTION_CHAT_MESSAGE_RECEIVED = "ACTION_CHAT_MESSAGE_RECEIVED", // DONE
    ACTION_CHAT_MESSAGE_RECEIVED_EMAIL = "ACTION_CHAT_MESSAGE_RECEIVED_EMAIL", // DONE

    ACTION_APPLIED = "ACTION_APPLIED", // DONE
    ACTION_REVIEWED = "ACTION_REVIEWED",

    ACTION_INTERVIEW_PENDING = "ACTION_INTERVIEW_PENDING", // DONE
    ACTION_INTERVIEW_REQUEST_NEW_TIMESLOTS = "ACTION_INTERVIEW_REQUEST_NEW_TIMESLOTS", // DONE
    ACTION_INTERVIEW_CONFIRMATION = "ACTION_INTERVIEW_CONFIRMATION", // DONE
    ACTION_INTERVIEW_SCHEDULED = "ACTION_INTERVIEW_SCHEDULED", // DONE
    ACTION_INTERVIEW_RESCHEDULED = "ACTION_INTERVIEW_RESCHEDULED", // DONE
    ACTION_INTERVIEW_RESCHEDULED_APPLICANT = "ACTION_INTERVIEW_RESCHEDULED_APPLICANT", // DONE
    ACTION_INTERVIEW_CANCELED = "ACTION_INTERVIEW_CANCELED", // DONE
    ACTION_INTERVIEW_CANCELED_APPLICANT = "ACTION_INTERVIEW_CANCELED_APPLICANT", // DONE
    ACTION_INTERVIEWED = "ACTION_INTERVIEWED", // DONE

    ACTION_TRIAL_PENDING = "ACTION_TRIAL_PENDING", // DONE
    ACTION_TRIAL_REQUEST_NEW_TIMESLOTS = "ACTION_TRIAL_REQUEST_NEW_TIMESLOTS", // DONE
    ACTION_TRIAL_CONFIRMATION = "ACTION_TRIAL_CONFIRMATION", // DONE
    ACTION_TRIAL_SCHEDULED = "ACTION_TRIAL_SCHEDULED", // DONE
    ACTION_TRIAL_RESCHEDULED = "ACTION_TRIAL_RESCHEDULED", // DONE
    ACTION_TRIAL_RESCHEDULED_APPLICANT = "ACTION_TRIAL_RESCHEDULED_APPLICANT",
    ACTION_TRIAL_CANCELED = "ACTION_TRIAL_CANCELED", // DONE
    ACTION_TRIAL_CANCELED_APPLICANT = "ACTION_TRIAL_CANCELED_APPLICANT", // DONE
    ACTION_TRIALED = "ACTION_TRIALED", // DONE

    ACTION_CONTRACT_PENDING = "ACTION_CONTRACT_PENDING", // DONE
    ACTION_CONTRACT_CANCELED = "ACTION_CONTRACT_CANCELED", // FOR LATER
    ACTION_CONTRACT_CONFIRMED = "ACTION_CONTRACT_CONFIRMED", // FOR LATER

    ACTION_HIRED = "ACTION_HIRED", // DONE

    ACTION_REJECTED = "ACTION_REJECTED", // DONE
    ACTION_USER_CALL = "ACTION_USER_CALL",

    ACTION_SHARED_POOL_FAVOURITE = "ACTION_SHARED_POOL_FAVOURITE",
    ACTION_SHARED_POOL_INTRODUCTION = "ACTION_SHARED_POOL_INTRODUCTION",
    ACTION_SHARED_POOL_REMOVE = "ACTION_SHARED_POOL_REMOVE",
    ACTION_SHARED_POOL_DUPLICATE_APPLICANT_RESPONSE = "ACTION_SHARED_POOL_DUPLICATE_APPLICANT_RESPONSE",
    ACTION_SHARED_POOL_APPLICANT_ACCEPT = "ACTION_SHARED_POOL_APPLICANT_ACCEPT",
    ACTION_SHARED_POOL_APPLICANT_DECLINE = "ACTION_SHARED_POOL_APPLICANT_DECLINE",
    ACTION_SHARED_POOL_APPLICANT_OPT_OUT = "ACTION_SHARED_POOL_APPLICANT_OPT_OUT",
}

export enum AnalyticsEventTrigger {
    USER = "USER",
    APPLICANT = "APPLICANT",
    SYSTEM = "SYSTEM",
}

export enum CommunicationSource {
    EMAIL = "EMAIL",
    SMS = "SMS",
    VOICE_CALL = "VOICE_CALL",
    APP = "APP",
}
/**
 * Currently used for manual state updates by the user
 */
export const StateToActivityMap = {
    [ApplicationState.APPLIED]: ActivityType.ACTION_APPLIED,
    [ApplicationState.REVIEWED]: ActivityType.ACTION_REVIEWED,

    [ApplicationState.INTERESTED]: ActivityType.ACTION_INTERESTED,
    [ApplicationState.INTERVIEW_PENDING]: ActivityType.ACTION_INTERVIEW_PENDING,
    [ApplicationState.INTERVIEW_CANCELED]: ActivityType.ACTION_INTERVIEW_CANCELED,
    [ApplicationState.INTERVIEWED]: ActivityType.ACTION_INTERVIEWED,

    [ApplicationState.TRIAL_PENDING]: ActivityType.ACTION_TRIAL_PENDING,
    [ApplicationState.TRIAL_CANCELED]: ActivityType.ACTION_TRIAL_CANCELED,
    [ApplicationState.TRIALED]: ActivityType.ACTION_TRIALED,

    [ApplicationState.HIRED]: ActivityType.ACTION_HIRED,

    [ApplicationState.REJECTED]: ActivityType.ACTION_REJECT,
};

export enum IntegrationType {
    PLANDAY = "planday",
}
