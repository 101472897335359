import React, { useState, useMemo } from "react";
import Column from "./Column";
import TimeSlot from "./TimeSlot";
import { ScheduleType, TimeSlotType, Booking } from "../types";
import Loader from "./Loader";
import { format } from "date-fns";
import { useCustomTrialPreferences } from "../hooks/useCustomTrialPreferences";
import { useRestaurantTrialBookings } from "../hooks/useRestaurantTrialBookings";
import { Dates, DateTimeConversion } from "@anna/shared";
import { isSelectedSlotMatching } from "../helpers/dates";

//TODO: Deal with UTC

export interface TrialTimesProps {
    readonly funnelId: string;
    readonly type: Contracts.BookingType;
    readonly token: string;
    readonly restaurantId: string;
    readonly selectedTimeSlot?: TimeSlotType;
    readonly setSelectedTimeSlot: (selectedTimeSlot: TimeSlotType) => void;
}

export default function TrialTimes(props: TrialTimesProps) {
    const { restaurantId, funnelId, type } = props;

    const [isLoading, setIsLoading] = useState(true);

    const bookedDates = useRestaurantTrialBookings(restaurantId, type, () =>
        setIsLoading(false),
    );
    const customPreference = useCustomTrialPreferences(funnelId, type, () =>
        setIsLoading(false),
    );

    const generatedSchedule = useMemo(() => generateCustomSchedule(customPreference), [
        customPreference,
    ]);

    if (isLoading) return <Loader />;
    return (
        <>
            {generatedSchedule
                .filter((day: ScheduleType) => day.timeSlots.length > 0)
                .map((day: ScheduleType) => {
                    const title = Dates.longLocalDateFormat(day.date);
                    return (
                        <Column key={day.dateName} title={title}>
                            <>
                                {day.timeSlots.map(timeSlot => {
                                    const startTimeString = DateTimeConversion.minutesToHoursAndMinutes(
                                        timeSlot.startTimeInMinutes,
                                    );
                                    const endTimeString = DateTimeConversion.minutesToHoursAndMinutes(
                                        timeSlot.endTimeInMinutes,
                                    );

                                    const isMatch = isSelectedSlotMatching({
                                        timeSlot,
                                        selectedTimeSlot: props.selectedTimeSlot || null,
                                    });

                                    const isBooked = bookedDates.find(
                                        (booking: Booking) =>
                                            booking.startTime.getTime() ===
                                                timeSlot.startTime.getTime() &&
                                            booking.endTime.getTime() ===
                                                timeSlot.endTime.getTime(),
                                    );

                                    return (
                                        <TimeSlot
                                            key={startTimeString}
                                            startTime={startTimeString}
                                            endTime={endTimeString}
                                            isBooked={!!isBooked}
                                            isSelected={!!isMatch}
                                            handleSelection={() =>
                                                props.setSelectedTimeSlot(timeSlot)
                                            }
                                        />
                                    );
                                })}
                            </>
                        </Column>
                    );
                })}
        </>
    );
}

function generateCustomSchedule(
    customPreference: Contracts.CustomPreferenceDTO,
): ScheduleType[] {
    return Object.keys(customPreference).map(dateString => {
        const startTimeInMinutes = customPreference[dateString].startTimeInMinutes;
        const endTimeInMinutes = customPreference[dateString].endTimeInMinutes;
        const date = new Date(dateString);
        const day = format(date, "yyyy-MM-dd");
        const startTime = DateTimeConversion.dayMinutesToDate(day, startTimeInMinutes);
        const endTime = DateTimeConversion.dayMinutesToDate(day, endTimeInMinutes);
        return {
            dateName: dateString.toString(),
            date,
            interval: endTimeInMinutes - startTimeInMinutes,
            timeSlots: [
                {
                    day,
                    startTimeInMinutes,
                    endTimeInMinutes,
                    startTime,
                    endTime,
                },
            ],
        };
    });
}
