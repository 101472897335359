import React from "react";
import { RouteComponentProps } from "react-router-dom";
import ShortenedLink from "../../components/ShortenedLink/index";

interface MatchParams {
    readonly token: string;
}

export default function ShortenedLinkRedirect({
    match,
}: RouteComponentProps<MatchParams>) {
    return <ShortenedLink token={match.params.token} />;
}
