import React from "react";
import useTranslation from "../../../../services/language";
import { View } from "react-native";
import AppText from "../../../Text/AppText";
import { useSharedPoolApplicantDecline } from "../../../../hooks/useSharedPoolApplicantActions";
import { SmallSpace } from "../../../Spacing";
import Header from "../Header";
import Footer from "../Footer";
import { sharedStyles } from "../styles";
import ScreenLoader from "../../../Loading/ScreenLoader";
import ExpiredLink from "../ExpiredLink";

export interface MatchParams {
    readonly token: string;
}

const DeclineContactRequest = (props: MatchParams) => {
    const { token } = props;
    const { isLoading, data } = useSharedPoolApplicantDecline(token);
    const l = useTranslation();

    if (isLoading) return <ScreenLoader />;

    if (data?.hasPreviousResponse) {
        return <ExpiredLink token={token} />;
    }

    const headlineText = l("Thanks");
    const subHeadlineText = l(
        "We will get back to you if another restaurant wants to get in touch",
    );

    return (
        <>
            <View style={sharedStyles.container}>
                <Header />

                <View style={sharedStyles.content}>
                    <AppText style={sharedStyles.headline}>{headlineText}!</AppText>
                    <SmallSpace />
                    <AppText style={sharedStyles.subHeadline}>{subHeadlineText}!</AppText>
                </View>

                <Footer />
            </View>
        </>
    );
};

export default DeclineContactRequest;
