import { Colors } from "./colors";

export interface ITheme {
    primaryColor: string;
    grey: string;
    darkestFont: string;
    darkFont: string;
    lightFont: string;
    lightBorder: string;
    chatBubbleExternalColor: string;
}

const theme: ITheme = {
    primaryColor: Colors.primaryColor,
    grey: "#d8d8d8",
    darkestFont: Colors.darkestFont,
    darkFont: Colors.darkFont,
    lightFont: Colors.lightFont,
    lightBorder: Colors.lightBorder,
    chatBubbleExternalColor: Colors.chatBubbleExternalColor,
};

export default theme;
