import React from "react";
import { View, StyleSheet } from "react-native";
import { StyleGuide, Spacing } from "../../constants/StyleGuide";

interface HeaderProps {
    readonly children: React.ReactNode;
    readonly marginBottom?: Spacing;
    readonly marginTop?: Spacing;
    readonly forStackModal?: boolean;
}

export function Header({
    children,
    marginBottom = StyleGuide.spacing.mini,
    marginTop = StyleGuide.spacing.base,
    forStackModal,
}: HeaderProps) {
    return (
        <View
            style={[
                styles.container,
                { marginBottom, marginTop },
                forStackModal && { marginTop: 0 },
            ]}>
            {/* @ts-ignore */}
            {children}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        marginHorizontal: StyleGuide.spacing.base,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
});
