import React from "react";
import styles from "./styles.module.scss";

export default function Column({
    title,
    children,
}: {
    title?: string;
    children: React.ReactElement;
}) {
    return (
        <div className={styles.container}>
            <div className={styles.title}>{title}</div>
            <div>{children}</div>
        </div>
    );
}
