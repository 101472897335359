export enum AppRoute {
    SharedTalentPool = "/talent-pool/applicants/:token",
    SharedTalentPoolAccept = "/sharedPoolContact/accept/:token",
    SharedTalentPoolDecline = "/sharedPoolContact/decline/:token",
    SharedTalentPoolOptOut = "/sharedPoolContact/optout/:token",

    InterviewSchedule = "/interview/schedule/:token",
    InterviewReschedule = "/interview/reschedule/:token",
    InterviewCancel = "/interview/cancel/:bookingId/:token",

    TrialSchedule = "/trial/schedule/:token",
    TrialReschedule = "/trial/reschedule/:token",
    TrialCancel = "/trial/cancel/:bookingId/:token",

    TypeformCompletion = "/complete/typeform",
    Message = "/message",
    Expired = "/expired",

    Redirect = "/:token",
}
