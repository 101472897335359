import React from "react";
import styles from "./styles.module.scss";
import anna from "../../assets/anna-logo.png";

export default function Expired() {
    return (
        <div className={styles.expired}>
            <img src={anna} />

            <p>This link has expired or has been used before.</p>
            <p>Please ask for a new link.</p>
        </div>
    );
}
