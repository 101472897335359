import React from "react";
import styles from "./styles.module.scss";
import joinCls from "../../styles/joinClasses";

interface TimeSlotProps {
    readonly startTime: string;
    readonly endTime?: string;
    readonly isSelected: boolean;
    readonly isBooked: boolean;
    readonly withEqualWidths?: boolean;
    readonly handleSelection: () => void;
}

export default function TimeSlot({
    startTime,
    endTime,
    isSelected,
    isBooked,
    withEqualWidths,
    handleSelection,
}: TimeSlotProps) {
    return (
        <button
            className={joinCls(
                styles.container,
                withEqualWidths && styles.containerWithEqualWidths,
                isBooked && styles.containerBooked,
                isSelected && styles.containerSelected,
            )}
            disabled={isBooked}
            onClick={handleSelection}>
            <div
                className={joinCls(
                    styles.time,
                    isBooked && styles.timeDisabled,
                    isSelected && styles.timeSelected,
                )}>
                {startTime}
                {endTime && ` - ${endTime}`}
            </div>
        </button>
    );
}
