import { __LOCAL__, __STAGING__ } from "./environment";

const ROOT = {
    LOCAL: "https://europe-west1-anna-dev-d1a8d.cloudfunctions.net",
    STAGING: "https://europe-west1-anna-dev-d1a8d.cloudfunctions.net",
    PRODUCTION: "https://europe-west1-all-anna.cloudfunctions.net",
};

export default {
    API_BASE: `${
        __LOCAL__ ? ROOT.LOCAL : __STAGING__ ? ROOT.STAGING : ROOT.PRODUCTION
    }/api/external/applicant`,

    FIREBASE_APP_API_KEY: __STAGING__
        ? "AIzaSyD9Tc9kvOizNgEr_50A5r0QTYqk1JYZS6I-xY63wai-W1ZXI"
        : "AIzaSyD9Tc9kvOizNgEr_50A5r0QTYqk1JYZS6I",
    FIREBASE_APP_AUTH_DOMAIN: __STAGING__
        ? "all-anna.firebaseapp.com"
        : "all-anna.firebaseapp.com",
    FIREBASE_APP_DATABASE_URL: __STAGING__
        ? "https://all-anna-default-rtdb.europe-west1.firebasedatabase.app"
        : "https://all-anna-default-rtdb.europe-west1.firebasedatabase.app",
    FIREBASE_APP_PRODJECT_ID: __STAGING__ ? "all-anna" : "all-anna",
    FIREBASE_APP_STORAGE_BUCKET: __STAGING__
        ? "all-anna.appspot.com"
        : "all-anna.appspot.com",
    FIREBASE_APP_MESSAGING_SENDER_ID: __STAGING__ ? "457277308274" : "457277308274",
    FIREBASE_APP_ID: __STAGING__
        ? "1:457277308274:web:48054e6dd4a8d0986e71eb"
        : "1:457277308274:web:48054e6dd4a8d0986e71eb",
    SENTRY_CONFIG: "https://343ba82aada54420b70930894d8fd44d@sentry.io/1724088",
};
