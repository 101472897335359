import React, { useState } from "react";
import Column from "./Column";
import TimeSlot from "./TimeSlot";
import { TimeSlotType, Booking } from "../types";
import Loader from "./Loader";
import { isBefore, isAfter } from "date-fns";
import { useInterviewCustomTimes } from "../hooks/useInterviewCustomTimes";
import { useUserInterviewBookings } from "../hooks/useUserInterviewBookings";
import useTranslation from "../services/language";
import { DateTimeConversion, Dates, Times } from "@anna/shared";
import { isSelectedSlotMatching } from "../helpers/dates";

//TODO: Deal with UTC

export interface InterviewTimesProps {
    readonly token: string;
    readonly userId: string;
    readonly funnelId: string;
    readonly selectedTimeSlot?: TimeSlotType;
    readonly setSelectedTimeSlot: (selectedTimeSlot: TimeSlotType) => void;
}

function InterviewTimes({
    userId,
    selectedTimeSlot,
    setSelectedTimeSlot,
    funnelId,
}: InterviewTimesProps) {
    const l = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const bookedDates = useUserInterviewBookings(userId, () => setIsLoading(false));
    const customTimes = useInterviewCustomTimes(userId, funnelId, () =>
        setIsLoading(false),
    );

    if (isLoading) return <Loader />;
    const render =
        customTimes &&
        Object.keys(customTimes.customTimeSlots)
            .sort()
            .map((day: string) => {
                const times = Times.convertTimeSlotsForDay(
                    day,
                    customTimes.customTimeSlots,
                    customTimes.interval,
                ).filter(timeSlot => isAfter(timeSlot.startTime, new Date())); // filter out days with no times

                const title = Dates.mediumLocalDateFormat(new Date(day));
                if (times.length < 1) {
                    return null;
                }
                return (
                    <Column key={day} title={title}>
                        <>
                            {times.map((timeSlot: TimeSlotType) => {
                                const startTimeFormat = DateTimeConversion.minutesToHoursAndMinutes(
                                    timeSlot.startTimeInMinutes,
                                );

                                // short circuit if time is in the past
                                if (isBefore(timeSlot.startTime, new Date())) {
                                    return null;
                                }

                                const isMatch = isSelectedSlotMatching({
                                    timeSlot,
                                    selectedTimeSlot: selectedTimeSlot || null,
                                });

                                const isBooked = bookedDates.some(
                                    (booking: Booking) =>
                                        // We only allow half hour slots
                                        booking.startTime.getTime() ===
                                            timeSlot.startTime.getTime() &&
                                        booking.endTime.getTime() ===
                                            timeSlot.endTime.getTime(),
                                );

                                return (
                                    <TimeSlot
                                        key={`${day}${startTimeFormat}`}
                                        startTime={startTimeFormat}
                                        isBooked={isBooked}
                                        isSelected={isMatch}
                                        withEqualWidths
                                        handleSelection={() =>
                                            setSelectedTimeSlot(timeSlot)
                                        }
                                    />
                                );
                            })}
                        </>
                    </Column>
                );
            });

    // check if we return any components else there is no times to select
    if (render?.filter((item: any) => item).length === 0) {
        return (
            <h4
                style={{
                    fontSize: 14,
                    fontWeight: 400,
                    padding: 5,
                    paddingLeft: 24,
                    paddingRight: 24,
                    textAlign: "center",
                }}>
                {l(
                    'No times available, please reach out to us by choosing the option "I can\'t make these times" so we can find a new time, thanks!',
                )}
            </h4>
        );
    }

    return <>{render}</>;
}

export default InterviewTimes;
