import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { MatchParams } from "./index";
import AcceptContactRequest from "../../components/SharedPool/Applicant/AcceptContactRequest";
import { getTokenDetails } from "../../helpers/token";
import ExpiredLink from "../../components/SharedPool/Applicant/ExpiredLink/index";

export default function SharedPoolContactAccept({
    match,
}: RouteComponentProps<MatchParams>) {
    const token = match.params.token;
    const { isExpired } = getTokenDetails({ token });

    if (isExpired) return <ExpiredLink token={token} />;
    return <AcceptContactRequest token={token} />;
}
