import React from "react";
import useTranslation from "../../../../services/language";
import { View } from "react-native";
import { useSharedPoolApplicantOptout } from "../../../../hooks/useSharedPoolApplicantActions";
import AppText from "../../../Text/AppText";
import { SmallSpace } from "../../../Spacing/index";
import Header from "../Header";
import Footer from "../Footer";
import { sharedStyles } from "../styles";
import ScreenLoader from "../../../Loading/ScreenLoader";

export interface MatchParams {
    readonly token: string;
}

const OptoutContactRequest = (props: MatchParams) => {
    const { token } = props;
    const { isLoading } = useSharedPoolApplicantOptout(token);
    const l = useTranslation();

    if (isLoading) return <ScreenLoader />;

    const headlineText = l("Adios my friend!");
    const subHeadlineText = l(
        "You will no longer be contacted with job offers from restaurants in the anna community!",
    );

    return (
        <>
            <View style={sharedStyles.container}>
                <Header />

                <View style={sharedStyles.content}>
                    <AppText style={sharedStyles.headline}>{headlineText}</AppText>
                    <SmallSpace />
                    <AppText style={sharedStyles.subHeadline}>{subHeadlineText}</AppText>
                </View>

                <Footer />
            </View>
        </>
    );
};

export default OptoutContactRequest;
