import React from "react";
import { StyleSheet, View } from "react-native";
import { StyleGuide } from "../constants/StyleGuide";
import { Colors } from "../constants/colors";
import AppText from "./Text/AppText";

export default function Tags({ title, tags = [] }: { title: string; tags: string[] }) {
    return (
        <View>
            <View style={{ width: "100%", flexDirection: "row", alignItems: "center" }}>
                <AppText style={styles.title}>{title}</AppText>
            </View>
            <View style={styles.tagWrapper}>
                {tags.map((tag, index) => (
                    <View
                        key={`${tag}${index}`}
                        style={[
                            styles.tagView,
                            { backgroundColor: Colors.chatBubbleExternalColor },
                        ]}>
                        <AppText style={styles.tagText}>{tag}</AppText>
                    </View>
                ))}
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    title: StyleGuide.typography.body,
    tagText: {
        ...StyleGuide.typography.bodyLight,
        color: "#030303",
    },
    tagWrapper: {
        overflow: "visible",
        paddingTop: 10,
        paddingBottom: 10,
        flexDirection: "row",
        flexWrap: "wrap",
    },
    tagView: {
        padding: 7,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 50,
        marginRight: 10,
        marginBottom: 10,
    },
});
