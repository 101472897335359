import React, { Component } from "react";
import * as Sentry from "@sentry/browser";

interface IProps {
    children: any;
}

interface IState {
    eventId: string | undefined;
    hasError: boolean;
}

class ErrorBoundary extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            hasError: false,
            eventId: undefined,
        };
    }

    static getDerivedStateFromError(_: Error) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: any) {
        console.log({ componentDidCatch: error });

        Sentry.withScope(scope => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({ eventId, hasError: true });
        });
    }

    render() {
        if (this.state.hasError) {
            //render fallback UI
            return (
                <button
                    onClick={() =>
                        Sentry.showReportDialog({ eventId: this.state.eventId })
                    }>
                    Report feedback
                </button>
            );
        }

        //when there's not an error, render children untouched
        return this.props.children;
    }
}

export default ErrorBoundary;
