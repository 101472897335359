export function capitilize(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export function truncate(text: string, limit: number = 20) {
    const regex = new RegExp(`(.{${limit}})..+`);
    return text?.replace(regex, "$1...");
}

/**
 * Appends the suffix if `count` is plural (not equal to 1)
 * @param text The text to append the suffix to
 * @param count The
 */
export function withSuffix(text: string, count: number) {
    const suffix = count === 1 ? "" : "s";
    return `${text}${suffix}`;
}
