import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Cancel from "../../components/Cancel";
import { SchedulingType } from "@anna/shared";

interface MatchParams {
    readonly token: string;
}

export default function TrialCancel({ match }: RouteComponentProps<MatchParams>) {
    return <Cancel schedulingType={SchedulingType.Trial} token={match.params.token} />;
}
