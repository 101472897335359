import React from "react";

/**
 * Splits the text by `\n` using break line elements
 * @param text Text to be formatted
 */
export function addLineBreaks(text: string) {
    const textSplitted = text.split("\n");
    return textSplitted.map((t, i) => (
        <React.Fragment key={i}>
            {t}
            {i < textSplitted.length - 1 && <br />}
        </React.Fragment>
    ));
}
