import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Reschedule from "../../components/Reschedule";
import { SchedulingType } from "@anna/shared";

interface MatchParams {
    readonly token: string;
}

export default function InterviewReschedule({ match }: RouteComponentProps<MatchParams>) {
    return (
        <Reschedule
            schedulingType={SchedulingType.Interview}
            token={match.params.token}
        />
    );
}
