import React from "react";
import { TouchableOpacity, Image } from "react-native";

export function AppAvatar({ size, source }) {
    return (
        <TouchableOpacity>
            <Image
                style={{ height: size, width: size, borderRadius: size }}
                source={source}
            />
        </TouchableOpacity>
    );
}
