import React from "react";
import { StyleSheet, View } from "react-native";
import Spinner from "./Spinner";

export default function () {
    return (
        <View style={styles.container}>
            <Spinner />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
});
